import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mobileAppConnect } from "src/api/mobileAppConnect";
import { QueryKeys } from "src/constants/queryKeys";
import { StatusCodes } from "src/constants/statuses";

type Options = {
  onPaymentRequired?: () => void;
  onSuccess?: () => void;
};

export const useConnectMobileApp = ({
  onPaymentRequired,
  onSuccess,
}: Options = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => mobileAppConnect({ mobile_app_id: id }),
    mutationKey: [QueryKeys.MobileAppConnect],
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.MobileApps],
        type: "all",
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.MobileApp],
        type: "all",
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.MobileAppVersions],
        type: "all",
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.MobileAppVersion],
        type: "all",
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.CurrentUser],
        type: "all",
      });
    },
    onError: (error) => {
      if (error.response?.status === StatusCodes.PAYMENT_REQUIRED) {
        if (onPaymentRequired) {
          onPaymentRequired();
          return;
        }
      }
    },
  });
};
