export const commonColors = {
    black: "#0E0F0F",
    softBlack: "#25272B",
    white: "#FFFFFF",
    gray: "#DAE3EC",
    softGray: "#E9EEF4",
    darkGray: "#818A99",
    darkGray2: "#525F74",
    darkGray3: "#3A3F49",
    blue: "#2D74DF",
    blueNuclear: "#0F56FF",
    blueDust: "#99B4DC",
    red: "#DF3D3D",
    redLight: "#F26A6A",
    green: "#11E58C",
    greenBlueMix: "#0AD3AF",
    gradient: "linear-gradient(90deg, #5DFBE8 -1.83%, #8AA9FB 115.85%)",
};
