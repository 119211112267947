import { paths } from "@flowriver/schema";
import { api } from "src/utils/api";

type Response =
  paths["/api/v1/payment_orders/:id/complete"]["post"]["responses"]["200"]["content"]["application/json"];

type Options = {
  id: number;
};

export const paymentOrderComplete = ({ id }: Options) =>
  api.post<Response>(`/v1/payment_orders/${id}/complete`);
