import { Button } from "@mui/base";
import { styled } from "@mui/material";
export const IconButton = styled(Button) `
  display: flex;
  padding: ${({ theme }) => theme.spacing(1)};
  min-width: 0;
  border-radius: 30%;
  border: 0;
  font-size: 14px;
  background-color: transparent;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.text.secondary};

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
  &:active {
    background-color: ${({ theme }) => theme.palette.action.active};
  }
`;
