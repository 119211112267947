import { useQuery } from "@tanstack/react-query";
import { sessionGet } from "src/api/sessionGet";
import { QueryKeys } from "src/constants/queryKeys";

export const useUserData = () => {
  const { data: user, isLoading } = useQuery({
    queryFn: () => sessionGet(),
    queryKey: [QueryKeys.WhoAmI],
  });

  const email: string | undefined = user?.identity?.traits.email;
  const firstName: string | undefined = user?.identity?.traits.first_name;
  const lastName: string | undefined = user?.identity?.traits.last_name;
  const picture: string | undefined = user?.identity?.traits.picture;

  return { isLoading, user, email, firstName, lastName, picture };
};
