export var TypographyType;
(function (TypographyType) {
    TypographyType["H1"] = "h1";
    TypographyType["H2"] = "h2";
    TypographyType["H3"] = "h3";
    TypographyType["Title1"] = "title1";
    TypographyType["Title2"] = "title2";
    TypographyType["Title3"] = "title3";
    TypographyType["Subtitle1"] = "subtitle1";
    TypographyType["Subtitle2"] = "subtitle2";
    TypographyType["Subtitle3"] = "subtitle3";
    TypographyType["Body1"] = "body1";
    TypographyType["Body2"] = "body2";
    TypographyType["Body3"] = "body3";
    TypographyType["Body4"] = "body4";
    TypographyType["Body5"] = "body5";
})(TypographyType || (TypographyType = {}));
export var ModalCloseReason;
(function (ModalCloseReason) {
    ModalCloseReason["BackdropClick"] = "backdropClick";
    ModalCloseReason["EscapeKeyDown"] = "escapeKeyDown";
})(ModalCloseReason || (ModalCloseReason = {}));
