import { Box } from "./Box";
import { css } from "./helpers/css";
import { styled } from "./helpers/styled";
import { ThemeMode } from "./theme";
export const Paper = styled(Box) `
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: 0px 11px 22px 0px rgba(0, 10, 19, 0.025);
  border-radius: ${({ theme }) => theme.spacing(4)};
  overflow: hidden;

  ${({ variant, theme }) => variant === "secondary" &&
    css `
      background-color: ${theme.palette.mode === ThemeMode.light ? '#f6f8fb' : theme.palette.common.softBlack};
      box-shadow: none;
      border-radius: ${theme.spacing(1)};
    `}
`;
