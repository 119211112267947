export enum QueryKeys {
  MobileAppVersions = "mobileAppVersions",
  MobileAppVersion = "mobileAppVersion",
  MobileApp = "mobileApp",
  MobileApps = "mobileApps",
  MobileAppTouch = "mobileAppTouch",
  MobileAppConnect = "mobileAppConnect",
  MobileAppPublic = "mobileAppPublic",
  AppScreens = "appScreens",
  AppFlows = "appFlows",
  AppFlowScreens = "appFlowScreens",
  ScreenElements = "screenElements",
  Versions = "versions",
  VersionPublic = "versionPublic",
  RegistrationFlow = "registrationFlow",
  CreateLoginFlow = "createLoginFlow",
  CreateVerificationFlow = "createVerificationFlow",
  GetVerificationFlow = "getVerificationFlow",
  CreateRecoveryFlow = "createRecoveryFlow",
  GetRecoveryFlow = "getRecoveryFlow",
  UpdateRecoveryFlow = "updateRecoveryFlow",
  CreateBrowserSettingsFlow = "createBrowserSettingsFlow",
  UpdateSettingsFlow = "updateSettingsFlow",
  WhoAmI = "whoAmI",
  CurrentUser = "currentUser",
  Products = "products",
  PaymentOrderCreate = "paymentOrderCreate",
  PaymentOrderComplete = "paymentOrderComplete",
  WebApp = "webApp",
  WebApps = "webApps",
  DashboardPublic = "dashboardPublic",
  DashboardConnect = "dashboardConnect",
}
