import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal as BaseModal } from "@mui/base";
import { css, Fade } from "@mui/material";
import { forwardRef } from "react";
import { Box } from "./Box";
import { styled } from "./helpers/styled";
import { IconButton } from "./IconButton";
import { CloseIcon } from "./icons/CloseIcon";
const ModalStyled = styled(BaseModal) `
  position: fixed;
  inset: 0;
  z-index: 1300;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ModalContent = styled(Box)(({ theme, variant }) => css `
    position: relative;
    background-color: ${theme.palette.background.paper};
    outline: none;

    ${variant === "primary" &&
    css `
      box-shadow: 3px 3px 0px 0px ${theme.palette.common.black};
      border: 1px solid ${theme.palette.common.black};
      border-radius: ${theme.spacing(3)};
    `}

    ${variant === "secondary" &&
    css `
      border-radius: ${theme.spacing(1)};
    `}

    z-index: 1;
    max-height: 97vh;
    overflow-y: auto;
    overflow-x: visible;
  `);
const StyledBackdrop = styled("div") `
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.65);
`;
const BackdropSlot = forwardRef((props, ref) => {
    const { open, ...other } = props;
    return (_jsx(Fade, { in: open, children: _jsx(StyledBackdrop, { ref: ref, ...other }) }));
});
const IconButtonStyled = styled(IconButton) `
  position: absolute;
  top: ${({ theme }) => theme.spacing(4)};
  right: ${({ theme }) => theme.spacing(4)};
`;
export const Modal = forwardRef(({ children, closeIcon = _jsx(CloseIcon, { sx: { color: "common.black" } }), onClose, variant = "primary", ...props }, ref) => {
    return (_jsx(ModalStyled, { ref: ref, ...props, slots: { backdrop: BackdropSlot }, closeAfterTransition: true, onClose: onClose, children: _jsx(Fade, { in: props.open, children: _jsxs(ModalContent, { variant: variant, children: [closeIcon ? (_jsx(IconButtonStyled, { onClick: onClose, children: closeIcon })) : null, _jsx(_Fragment, { children: children })] }) }) }));
});
