import {
  Button,
  CloseIcon,
  Grid,
  HandIcon,
  Modal,
  Typography,
} from "@flowriver/ui-kit";
import { useMutation } from "@tanstack/react-query";
import { Props as ModalProps } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { bookCall } from "src/api/bookCall";
import { useUserData } from "src/hooks/useUserData";

type Props = {
  onBook: () => void;
} & ModalProps;

export const BookCallModal: FC<Props> = ({ open = true, onClose, onBook }) => {
  const { t } = useTranslation();

  const { mutate, isPending } = useMutation({ mutationFn: bookCall });
  const { email } = useUserData();

  const handleSuccessBook = () => {
    enqueueSnackbar(t("shared.book_call_modal.success_book"), {
      variant: "success",
    });
    onBook();
    onClose();
  };

  const handleErrorBook = () =>
    enqueueSnackbar(t("errors.unknown"), {
      variant: "error",
    });

  const handleBookClick = () => {
    if (!email) {
      return;
    }

    mutate(
      { email },
      {
        onSuccess: handleSuccessBook,
        onError: handleErrorBook,
      },
    );
  };

  const submitDisabled = !email || isPending;

  return (
    <Modal
      open={Boolean(open)}
      variant="secondary"
      closeIcon={<CloseIcon onClick={onClose} />}
    >
      <Grid
        container
        flexDirection="column"
        maxWidth={780}
        width="100vw"
        p={16}
        pb={8}
      >
        <Grid mb={12}>
          <Typography fontSize={30} fontWeight={600} fontFamily="Space Grotesk">
            {t("shared.book_call_modal.title")}
          </Typography>
        </Grid>
        <Grid mb={4}>
          <Typography
            variant="subtitle2"
            fontFamily="Space Grotesk"
            color="text.secondary"
          >
            <Trans
              i18nKey="shared.book_call_modal.description"
              components={{
                bold: (
                  <Typography
                    component="span"
                    variant="subtitle2"
                    fontFamily="Space Grotesk"
                    color="text.primary"
                  />
                ),
              }}
            />
          </Typography>
        </Grid>
        <Grid mb={4}>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            fontFamily="Space Grotesk"
          >
            {t("shared.book_call_modal.description2")}
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          <HandIcon sx={{ fontSize: 120, color: "primary.main" }} />
        </Grid>
        <Grid container justifyContent="center">
          <Button
            variant="contained"
            size="large"
            color="primary"
            sx={{ px: 14 }}
            disabled={submitDisabled}
            onClick={handleBookClick}
          >
            {t("shared.book_call_modal.submit_button")}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
