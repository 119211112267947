import { Tab as TabBase, tabClasses, TabsList as TabsListBase, } from "@mui/base";
export { TabPanel, Tabs } from "@mui/base";
import { css, styled } from "@mui/material";
export const TabsList = styled(TabsListBase)(({ theme, withoutMargin = false }) => css `
    display: flex;
    gap: ${theme.spacing(6)};
    border-bottom: 1px solid ${theme.palette.divider};

    ${!withoutMargin &&
    css `
      margin-top: ${theme.spacing(4)};
      margin-bottom: ${theme.spacing(7)};
    `}
  `);
export const Tab = styled(TabBase) `
  background: transparent;
  padding: 0;
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  border: 0 none;
  border-bottom: 3px solid transparent;
  margin-bottom: -1px;
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  line-height: ${({ theme }) => theme.typography.body2.lineHeight};
  font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
  color: ${({ theme }) => theme.palette.text.secondary};

  &:hover {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  &.${tabClasses.selected} {
    border-color: ${({ theme }) => theme.palette.text.secondary};
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;
