const SS_KEY_PREFIX = "FR_";

export const addSSPrefix = (key: string) => `${SS_KEY_PREFIX}${key}`;

export const useSessionStorage = () => {
  const setItem = (key: string, value: string): void => {
    sessionStorage.setItem(addSSPrefix(key), value);
  };

  const getItem = (key: string): string | null => {
    return sessionStorage.getItem(addSSPrefix(key));
  };

  const removeItem = (key: string): void => {
    sessionStorage.removeItem(addSSPrefix(key));
  };

  return {
    setItem,
    getItem,
    removeItem,
  };
};
