import { components } from "@flowriver/schema";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { mobileAppPost } from "src/api/mobileAppPost";
import { useAppErrorModals } from "src/components/AppErrorModals/useAppErrorModals";
import { usePayment } from "src/components/PaymentModal/hooks/usePayment";
import { queryClient } from "src/configs/queryClient";
import { CreateAppErrorCodes } from "src/constants/CreateAppErrorCodes";
import { QueryKeys } from "src/constants/queryKeys";
import { Routes } from "src/constants/routes";
import { StatusCodes } from "src/constants/statuses";

type Props = {
  onSuccess?: () => void;
  onErrorModal?: () => void;
  onError?: (message: string) => void;
  onPaymentRequired?: () => void;
  withRedirect?: boolean;
};

type ValidationError =
  components["responses"]["CreateMobileApp422"]["content"]["application/json"];

export const useAddMobileApp = ({
  onSuccess,
  onErrorModal,
  onError,
  onPaymentRequired,
  withRedirect,
}: Props) => {
  const navigate = useNavigate();

  const { chooseProduct } = usePayment();

  const {
    showPaidError,
    showIosError,
    showUnsupportedError,
    showUnavailableError,
  } = useAppErrorModals();

  const result = useMutation<AxiosResponse, AxiosError, { url: string }>({
    mutationFn: ({ url }) => mobileAppPost({ url }),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.MobileApps],
        type: "all",
      });

      queryClient.invalidateQueries({
        queryKey: [QueryKeys.Versions],
        type: "all",
      });

      queryClient.invalidateQueries({
        queryKey: [QueryKeys.CurrentUser],
        type: "all",
      });

      if (onSuccess) {
        onSuccess();
      }

      if (withRedirect) {
        navigate(Routes.MobileApps);
      }
    },
    onError: (error, { url }) => {
      const responseStatus = error.response?.status;

      if (responseStatus === StatusCodes.PAYMENT_REQUIRED) {
        if (onPaymentRequired) {
          onPaymentRequired();
        }
        const onPurchase = () => result.mutate({ url });

        return chooseProduct({ onPurchase });
      }

      if (
        responseStatus &&
        responseStatus >= StatusCodes.INTERNAL_SERVER_ERROR
      ) {
        if (onErrorModal) {
          onErrorModal();
        }
        showUnavailableError();
        return;
      }

      if (responseStatus === StatusCodes.UNPROCESSABLE_ENTITY) {
        const responseData = error?.response?.data as ValidationError;

        const hasPaidError = responseData.errors?.url?.some(
          (error) => error.code === CreateAppErrorCodes.Paid,
        );

        const hasBlockedError = responseData.errors?.url?.some(
          (error) => error.code === CreateAppErrorCodes.Blocked,
        );

        const hasUnavailableError = responseData.errors?.url?.some(
          (error) => error.code === CreateAppErrorCodes.Unavailable,
        );

        const hasUnsupportedPlatformError = responseData.errors?.url?.some(
          (error) => error.code === CreateAppErrorCodes.StoreUnavailable,
        );

        if (hasPaidError) {
          if (onErrorModal) {
            onErrorModal();
          }
          showPaidError();
          return;
        }

        if (hasUnavailableError) {
          if (onErrorModal) {
            onErrorModal();
          }
          showUnavailableError();
          return;
        }

        if (hasBlockedError || hasUnavailableError) {
          if (onErrorModal) {
            onErrorModal();
          }
          showUnsupportedError();
          return;
        }

        if (hasUnsupportedPlatformError) {
          if (onErrorModal) {
            onErrorModal();
          }
          showIosError();
          return;
        }

        const responseUrlError = responseData?.errors?.url
          ?.map(({ error }) => error)
          .join(", ");

        if (onError && responseUrlError) {
          onError(responseUrlError);
        }

        return;
      }
    },
  });

  return result;
};
