import { jsx as _jsx } from "react/jsx-runtime";
import { Switch as SwitchBase, switchClasses, } from "@mui/base";
import { css, styled } from "@mui/material";
import { ThemeMode } from "./theme";
const CustomSwitch = styled(SwitchBase)(({ theme: { palette }, size = "medium" }) => css `
    width: 42px;
    height: 26px;
    padding: 0;
    display: flex;
    position: relative;
    cursor: pointer;

    & .${switchClasses.thumb} {
      position: absolute;
      left: 2px;
      top: 2px;
      box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
      height: 22px;
      padding: 0 4px;
      border-radius: 11px;
      transition: 200ms transform;
      background-color: ${palette.mode === ThemeMode.light
    ? palette.common.blue
    : palette.common.darkGray2};
      font-size: 11px;
      color: ${palette.common.white};

      display: flex;
      align-items: center;
      justify-content: center;

      user-select: none;
    }

    &.${switchClasses.checked} .${switchClasses.thumb} {
      transform: translateX(13px);
      background-color: ${palette.mode === ThemeMode.light
    ? palette.common.white
    : palette.common.blue};
      color: ${palette.mode === ThemeMode.light
    ? palette.common.black
    : palette.common.white};
    }

    & .${switchClasses.track} {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 13px;
      background-color: ${palette.mode === ThemeMode.light
    ? palette.common.blueNuclear
    : "#19191A"};
      box-sizing: border-box;
    }

    & .${switchClasses.input} {
      display: none;
    }

    &.${switchClasses.disabled} {
      cursor: not-allowed;

      & .${switchClasses.thumb} {
        background-color: ${palette.secondary.disabled};
      }
      & .${switchClasses.track} {
        background-color: ${palette.secondary.disabled};
      }
    }

    ${size === "small" &&
    css `
      width: 36px;
      height: 18px;

      & .${switchClasses.thumb} {
        height: 14px;
        width: 20px;
        border-radius: 33px;
        font-size: 9px;
        font-weight: 500;
      }

      &.${switchClasses.checked} .${switchClasses.thumb} {
        transform: translateX(12px);
      }
    `}
  `);
const Thumb = ({ ownerState, ...props }) => {
    return _jsx("span", { ...props, children: ownerState.checked ? "ON" : "Off" });
};
export const Switch = (props) => (_jsx(CustomSwitch, { slots: { thumb: Thumb }, ...props }));
