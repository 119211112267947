import { Typography } from "@flowriver/ui-kit";
import { FC } from "react";
import { useUserData } from "src/hooks/useUserData";

export const UserInfo: FC = () => {
  const { email, firstName, lastName } = useUserData();

  const lastNameText = lastName ? " " + lastName : "";

  return (
    <>
      {firstName ? (
        <Typography variant="body2" pb={1} sx={{ color: "text.primary" }}>
          {firstName}
          {lastNameText}
        </Typography>
      ) : null}

      {email ? (
        <Typography variant="body3" sx={{ color: "text.secondary" }}>
          {email}
        </Typography>
      ) : null}
    </>
  );
};
