import { Configuration, FrontendApi } from "@ory/client";
import { AUTH_URL } from "src/constants/environments";

export const ory = new FrontendApi(
  new Configuration({
    basePath: AUTH_URL,
    baseOptions: {
      withCredentials: true,
    },
  }),
);
