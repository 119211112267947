import { CloseIcon, Grid, Modal, Typography } from "@flowriver/ui-kit";
import { Props as ModalProps } from "mui-modal-provider";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import unsupportedStatusImg from "src/assets/unsupported-status.svg";
import { ReportInput } from "src/components/ReportInput/ReportInput";
import { SupportBlock } from "src/components/SupportBlock";
import { Routes } from "src/constants/routes";

export const UnsupportedModal: FC<ModalProps> = ({ open = true, onClose }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleSuccessAddApp = () => {
    onClose();
    navigate(Routes.MobileApps);
  };

  return (
    <Modal
      open={Boolean(open)}
      variant="secondary"
      closeIcon={<CloseIcon onClick={onClose} />}
    >
      <Grid
        container
        flexDirection="column"
        alignItems="center"
        maxWidth={820}
        width="100vw"
        pt={9}
        pb={6}
        px={16}
      >
        <Grid mb={6}>
          <img src={unsupportedStatusImg} alt="paid" />
        </Grid>
        <Grid mb={5}>
          <Typography variant="h3">
            {t("app_error_modals.unsupported.title")}
          </Typography>
        </Grid>
        <Grid mb={9} maxWidth={555}>
          <Typography variant="body1" color="text.secondary" textAlign="center">
            {t("app_error_modals.unsupported.description")}
          </Typography>
        </Grid>
        <Grid width="100%" mb={22}>
          <ReportInput
            color="dark"
            size="medium"
            onSuccess={handleSuccessAddApp}
            onPaymentRequired={onClose}
            onErrorModal={onClose}
          />
        </Grid>
        <Grid>
          <SupportBlock />
        </Grid>
      </Grid>
    </Modal>
  );
};
