import { components } from "@flowriver/schema";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { webAppPost } from "src/api/webAppPost";
import { useAppErrorModals } from "src/components/AppErrorModals/useAppErrorModals";
import { usePayment } from "src/components/PaymentModal/hooks/usePayment";
import { queryClient } from "src/configs/queryClient";
import { Platforms } from "src/constants/Platforms";
import { QueryKeys } from "src/constants/queryKeys";
import { Routes } from "src/constants/routes";
import { StatusCodes } from "src/constants/statuses";
import { DEVICE_PLATFORMS_MAP } from "src/types/DEVICE_PLATFORMS_MAP";

type Props = {
  onSuccess?: () => void;
  onErrorModal?: () => void;
  onError?: (message: string) => void;
  onPaymentRequired?: () => void;
  withRedirect?: boolean;
};

type ValidationError =
  components["responses"]["CreateWebApp422"]["content"]["application/json"];

export const useAddWebApp = ({
  onSuccess,
  onErrorModal,
  onError,
  onPaymentRequired,
  withRedirect,
}: Props) => {
  const navigate = useNavigate();

  const { chooseProduct } = usePayment();

  const { showUnavailableError } = useAppErrorModals();

  const result = useMutation<
    AxiosResponse,
    AxiosError,
    { url: string; device?: Platforms }
  >({
    mutationFn: ({ url, device }) =>
      webAppPost({
        url,
        device: device ? DEVICE_PLATFORMS_MAP[device] : undefined,
      }),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.WebApps],
        type: "all",
      });

      queryClient.invalidateQueries({
        queryKey: [QueryKeys.CurrentUser],
        type: "all",
      });

      if (onSuccess) {
        onSuccess();
      }

      if (withRedirect) {
        navigate(Routes.Web2App);
      }
    },
    onError: (error, { url }) => {
      const responseStatus = error.response?.status;

      if (responseStatus === StatusCodes.PAYMENT_REQUIRED) {
        if (onPaymentRequired) {
          onPaymentRequired();
        }
        const onPurchase = () => result.mutate({ url });

        return chooseProduct({ onPurchase });
      }

      if (
        (responseStatus &&
          responseStatus >= StatusCodes.INTERNAL_SERVER_ERROR) ||
        responseStatus === StatusCodes.NOT_FOUND
      ) {
        if (onErrorModal) {
          onErrorModal();
        }
        showUnavailableError();
        return;
      }

      if (responseStatus === StatusCodes.UNPROCESSABLE_ENTITY) {
        const responseData = error?.response?.data as ValidationError;

        const responseUrlError = responseData?.errors?.url
          ?.map(({ error }) => error)
          .join(", ");

        if (onError && responseUrlError) {
          onError(responseUrlError);
        }

        return;
      }
    },
  });

  return result;
};
