import { Grid, Typography } from "@flowriver/ui-kit";
import { divide } from "mathjs/number";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { plansDetails } from "src/components/ChoosePlan/constants/plansDetails";
import { Plan } from "src/components/ChoosePlan/types";
import { formatPrice } from "src/helpers/formatPrice";

type Props = {
  plan: Plan;
  active: boolean;
  onSelect: () => void;
};

const MIN_TOKENS_FOR_DISCOUNT = 2;

export const PlanItem: FC<Props> = ({ plan, active, onSelect }) => {
  const { t } = useTranslation();

  const { id, currency: currency, credits, price } = plan;

  const planDetails = id ? plansDetails[id] : undefined;

  const isBestOffer = planDetails?.bestOffer;
  const Icon = planDetails?.icon;
  const IconWrapper = planDetails?.IconWrapper;

  const discount =
    credits && price && credits >= MIN_TOKENS_FOR_DISCOUNT
      ? divide(parseFloat(price), credits)
      : undefined;

  const priceFormatted =
    price && currency
      ? formatPrice({ price: parseFloat(price), currency })
      : undefined;

  const discountFormatted =
    discount && price && currency
      ? formatPrice({ price: discount, currency })
      : undefined;

  return (
    <Grid
      container
      flexDirection="column"
      alignItems="center"
      width={164}
      pb={6}
      onClick={onSelect}
      tabIndex={0}
      sx={{
        outlineStyle: "solid",
        outlineWidth: active ? 2 : 1,
        outlineColor: active ? "primary.main" : "divider",
        borderRadius: 1,
        overflow: "hidden",
        cursor: "pointer",
        transition: "outline 0.1s ease",
        [`&:hover, &:focus`]: {
          outlineWidth: 2,
          outlineColor: "primary.main",
        },
      }}
    >
      {isBestOffer ? (
        <Grid
          container
          py={1}
          sx={{
            backgroundColor: "primary.main",
            alignSelf: "stretch",
            justifyContent: "center",
          }}
        >
          <Typography variant="body2" color="text.light">
            {t("choose_plan.best_offer")}
          </Typography>
        </Grid>
      ) : null}
      <Grid>
        {IconWrapper && Icon ? (
          <IconWrapper>
            <Icon active={active} />
          </IconWrapper>
        ) : null}
      </Grid>
      <Typography
        variant="title3"
        textAlign="center"
        fontFamily="Space Grotesk"
        mb={8}
      >
        {t("choose_plan.credits", { count: credits })}
      </Typography>
      <Grid container gap={2} alignItems="center">
        <Typography fontFamily="Space Grotesk" fontSize="16px" fontWeight={400}>
          {priceFormatted}
        </Typography>
        {discountFormatted ? (
          <Typography
            component="span"
            fontFamily="Space Grotesk"
            variant="body2"
            color="common.darkGray"
          >
            <Trans
              i18nKey="choose_plan.per_record"
              components={{
                discount: (
                  <Typography variant="body1" component="span">
                    {discountFormatted}
                  </Typography>
                ),
              }}
            />
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
};
