import {
  AllReportsIcon,
  FunnelIcon,
  IosIcon,
  UpdateIcon,
} from "@flowriver/ui-kit";
import { useTranslation } from "react-i18next";
import { Routes } from "src/constants/routes";

export const useGetTabs = () => {
  const { t } = useTranslation();

  return [
    {
      label: t("menu.web2App"),
      link: Routes.Web2App,
      icon: <FunnelIcon />,
      isBeta: false,
      isNew: true,
    },
    {
      label: t("menu.reports"),
      link: Routes.MobileApps,
      icon: <AllReportsIcon />,
      isBeta: false,
      isNew: false,
    },
    {
      label: t("menu.ios"),
      link: Routes.Ios,
      icon: <IosIcon />,
      isBeta: true,
      isNew: false,
    },
    {
      label: t("menu.updates"),
      link: Routes.Updates,
      icon: <UpdateIcon />,
      isBeta: false,
      isNew: false,
    },
  ];
};
