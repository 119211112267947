import { Skeleton } from "@flowriver/ui-kit";
import { FC } from "react";
import { Content } from "src/components/PaymentModal/steps/Payment/components/Content";
import { usePaymentStore } from "src/components/PaymentModal/store/usePaymentStore";

export const Payment: FC = () => {
  const { clientSecret, orderId } = usePaymentStore();

  if (!clientSecret || !orderId) {
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ width: "100vw", maxWidth: 1000, height: "90vh" }}
      />
    );
  }

  return <Content />;
};
