export enum AmplitudeEventName {
  // Product
  PARecentViewed = "PA_Recent - Viewed",
  PARecentOpenReportTapped = "PA_Recent - Open_Report - Tapped",
  PARecentOpenAppPageTapped = "PA_Recent - Open_App_Page - Tapped",
  PARecentAddAppTapped = "PA_Recent - Add_app - Tapped",
  PAAllReportsViewed = "PA_All_reports - Viewed",
  PAAllReportsOpenAppPageTapped = "PA_All_reports - Open_App_Page - Tapped",
  PAAllReportsAddAppTapped = "PA_All_reports - Add_app - Tapped",
  PAUpdatesViewed = "PA_Updates - Viewed",
  PAUpdatesOpenAppPageTapped = "PA_Updates - Open_App_Page - Tapped",
  PAAppPageOpenReportTapped = "PA_App_page - Open_Report - Tapped",
  PAProfileLogoutTapped = "PA_Profile - Logout - Tapped",
  PAHeaderGetReportTapped = "PA_Header - Get_report - Tapped",
  PAHeaderUpgradeTapped = "PA_Header - Upgrade - Tapped",
  PAReportOpened = "PA_Report - Opened",
  PAReportClosed = "PA_Report - Closed",
  IOSDummyViewed = "iOS_dummy - Viewed",

  Web2appViewed = "Web2app_reports - Viewed",
  Web2appOpenAppPageTapped = "Web2app_reports - Open_App_Page - Tapped",
  Web2appAddAppTapped = "Web2app_reports - Add_app - Tapped",

  // Billing
  PaywallViewed = "Paywall - Viewed",
  PaywallBuyTapped = "Paywall - Buy - Tapped",
  PaywallGetInTouchTapped = "Paywall - Get_in_touch - Tapped",
  PaywallDiscountViewed = "Paywall_Discount - Viewed",
  PaywallDiscountBuyTapped = "Paywall_Discount - Buy - Tapped",
  CheckoutViewed = "Checkout - Viewed",
  CheckoutPayTapped = "Checkout - Pay - Tapped",
  CheckoutSuccessViewed = "Checkout_Success - Viewed",
  CheckoutSuccessCloseTapped = "Checkout_Success - Close - Tapped",
  PurchaseComplited = "Purchase - Complited",

  // Auth
  LoginPageViewed = "Login_Page - Viewed",
  LoginCompleted = "Login - Completed",
  SignupPageViewed = "Signup_Page - Viewed",
  VerificationPageEmailVerificationViewed = "Verification_page - Email_verification - Viewed",
  SignupCompleted = "Signup - Completed",

  Page404Viewed = "404 - Viewed",
}
