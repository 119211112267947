import { CloseIcon, Modal } from "@flowriver/ui-kit";
import { Props as ModalProps } from "mui-modal-provider";
import { FC, Fragment, useEffect } from "react";
import { Steps } from "src/components/PaymentModal/constants/Steps";
import { ChooseTariff } from "src/components/PaymentModal/steps/ChooseTariff";
import { Payment } from "src/components/PaymentModal/steps/Payment/Payment";
import { Success } from "src/components/PaymentModal/steps/Success";
import { usePaymentStore } from "src/components/PaymentModal/store/usePaymentStore";

const StepsData = {
  [Steps.Payment]: {
    component: Payment,
    hasIcon: true,
  },
  [Steps.Success]: {
    component: Success,
    hasIcon: false,
  },
  [Steps.ChoseTariff]: {
    component: ChooseTariff,
    hasIcon: true,
  },
};

export const PaymentModal: FC<ModalProps> = ({ open = true, onClose }) => {
  const { step, setCloseModal, handleCloseModal } = usePaymentStore();

  const CurrentStep = step ? StepsData[step].component : Fragment;
  const hasIcon = step ? StepsData[step].hasIcon : false;

  useEffect(() => {
    setCloseModal(onClose);
  }, []);

  return (
    <Modal
      open={!!open}
      closeIcon={hasIcon ? <CloseIcon onClick={handleCloseModal} /> : null}
      variant="secondary"
    >
      <CurrentStep />
    </Modal>
  );
};
