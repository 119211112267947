import { useAnalytics } from "@flowriver/analytics";
import { Grid, Typography } from "@flowriver/ui-kit";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import checkIcon from "src/assets/check-icon.svg";
import { useFeedbackModal } from "src/components/AddCreditsFeedbackModal/useFeedbackModal";
import { ChoosePlan } from "src/components/ChoosePlan/ChoosePlan";
import { useCreatePaymentOrder } from "src/components/PaymentModal/hooks/useCreatePaymentOrder";
import { usePayment } from "src/components/PaymentModal/hooks/usePayment";
import { usePaymentStore } from "src/components/PaymentModal/store/usePaymentStore";
import { AmplitudeEventName } from "src/constants/analytics";

export const ChooseTariff: FC = () => {
  const { t } = useTranslation();

  const { amplitude } = useAnalytics();

  useEffect(() => {
    amplitude.logEvent(AmplitudeEventName.PaywallViewed);
  }, []);

  const { appId, startPayment, handleCloseModal } = usePaymentStore();

  const { chooseProduct } = usePayment();

  const { showFeedbackModal } = useFeedbackModal({
    onClose: () => {
      chooseProduct({ appId });
    },
  });

  const { mutate, isPending } = useCreatePaymentOrder({
    onSuccess: ({ clientSecret, orderId, productId }) => {
      startPayment({ clientSecret, orderId, productId });
    },
  });

  const handleBuyClick = (id: number) => mutate(id);

  const handleContractClick = () => {
    amplitude.logEvent(AmplitudeEventName.PaywallGetInTouchTapped);

    handleCloseModal();

    showFeedbackModal();
  };

  const features = [
    t("payment_modal.choose_tariff.features.screens"),
    t("payment_modal.choose_tariff.features.notifications"),
    t("payment_modal.choose_tariff.features.analysis"),
    t("payment_modal.choose_tariff.features.sharing"),
    t("payment_modal.choose_tariff.features.various_devices"),
    t("payment_modal.choose_tariff.features.ai_funnels"),
    t("payment_modal.choose_tariff.features.exports"),
  ];

  return (
    <Grid container flexDirection="column" width={820}>
      <Grid
        container
        flexDirection="column"
        alignItems="center"
        px={11}
        pt={10}
        pb={6}
        sx={{ backgroundColor: "common.softBlack" }}
      >
        <Grid mb={6}>
          <Typography variant="h3" color="dark.contrastText">
            {t("payment_modal.choose_tariff.title")}
          </Typography>
        </Grid>
        <Grid mb={5}>
          <Typography variant="body1" color="dark.disabled">
            {t("payment_modal.choose_tariff.description")}
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="center"
          gap={5}
          flexWrap="wrap"
          alignSelf="stretch"
        >
          {features.map((feature, index) => (
            <Grid container gap={1} key={`plan-feature-${index}`}>
              <Grid>
                <img src={checkIcon} alt="check" />
              </Grid>
              <Grid>
                <Typography variant="body3" color="text.disabled">
                  {feature}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container flexDirection="column" alignItems="center" pt={5} pb={7}>
        <Grid mb={7}>
          <Typography variant="subtitle2" color="text.secondary">
            {t("payment_modal.choose_tariff.tariffs_description")}
          </Typography>
        </Grid>
        <ChoosePlan
          isLoading={isPending}
          onBuyClick={handleBuyClick}
          onContactClick={handleContractClick}
        />
      </Grid>
    </Grid>
  );
};
