import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuItem as MenuItemBase, menuItemClasses, } from "@mui/base";
import { css, styled } from "@mui/material";
import { forwardRef } from "react";
const ICON_WRAPPER_CLASS = "icon-wrapper";
const MenuItemStyled = styled("li")(({ theme, active, color = "primary" }) => css `
    display: flex;
    width: 100%;
    padding: ${theme.spacing(3, 4)};
    text-overflow: ellipsis;
    overflow: hidden;
    flex-wrap: nowrap;
    font-size: ${theme.typography.subtitle2.fontSize};
    line-height: ${theme.typography.subtitle2.lineHeight};
    font-weight: ${theme.typography.subtitle2.fontWeight};

    cursor: pointer;
    color: ${theme.palette.text.header};
    justify-content: flex-start;
    align-items: center;
    gap: ${theme.spacing(2)};
    transition:
      background-color 0.3s ease-in-out,
      color 0.3s ease-in-out;
    border-radius: ${theme.spacing(1)};

    & .${ICON_WRAPPER_CLASS} {
      color: ${theme.palette.primary.disabled};
      display: flex;
    }

    & .${ICON_WRAPPER_CLASS}>*:nth-of-type(1) {
      font-size: 16px;
      transition: color 0.3s ease-in-out;
    }

    &:hover {
      font-weight: ${theme.typography.subtitle2.fontWeight};
      background-color: ${theme.palette.action.hover};
    }

    &:hover .${ICON_WRAPPER_CLASS} {
      color: ${theme.palette.primary.main};
    }

    ${active &&
    css `
      font-size: ${theme.typography.subtitle1.fontSize};
      line-height: ${theme.typography.subtitle1.lineHeight};
      font-weight: ${theme.typography.subtitle1.fontWeight};
      background-color: ${theme.palette.action.hover};
      cursor: default;

      & .${ICON_WRAPPER_CLASS} {
        color: ${theme.palette.primary.main};
      }

      &:hover {
        font-weight: ${theme.typography.subtitle1.fontWeight};
      }
    `}

    &:active {
      background-color: ${theme.palette.common.gray};
    }

    &.${menuItemClasses.focusVisible} {
      color: ${theme.palette.primary.main};
    }

    ${color === "dark" &&
    css `
      color: ${theme.palette.common.gray};

      &:hover {
        background-color: ${theme.palette.dark.light};
        color: ${theme.palette.common.white};
      }

      & .${ICON_WRAPPER_CLASS} {
        color: ${theme.palette.common.blue};
      }

      &:hover .${ICON_WRAPPER_CLASS} {
        color: ${theme.palette.common.white};
      }

      &:active {
        background-color: ${theme.palette.dark.dark};
      }

      &:active .${ICON_WRAPPER_CLASS} {
        color: ${theme.palette.common.white};
      }

      ${active &&
        css `
        background-color: ${theme.palette.dark.light};
        color: ${theme.palette.common.white};

        & .${ICON_WRAPPER_CLASS} {
          color: ${theme.palette.common.white};
        }
      `}
    `}
  `);
export const MenuItemSlot = forwardRef(({ icon, children, ...restProps }, ref) => (_jsxs(MenuItemStyled, { ...restProps, ref: ref, children: [icon ? _jsx("span", { className: ICON_WRAPPER_CLASS, children: icon }) : null, children] })));
export const MenuItem = (props) => (_jsx(MenuItemBase, { ...props, slots: { root: MenuItemSlot } }));
