import { Box, Grid, Link, Typography } from "@flowriver/ui-kit";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation } from "react-router-dom";
import checkIcon from "src/assets/check-icon.svg";
import arrowNext from "src/components/PlansBanner/assets/arrow-next.svg";
import bannerImage from "src/components/PlansBanner/assets/banner-image.svg";
import { LocalStorageKeys } from "src/constants/localStorageKeys";
import { Routes } from "src/constants/routes";
import { useGetCurrentUser } from "src/hooks/useGetCurrentUser";

export const PlansBanner: FC = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const { data: currentUser } = useGetCurrentUser();

  const isBannerHidden = Boolean(
    localStorage.getItem(LocalStorageKeys.TariffBannerHidden),
  );

  const [isBannerVisible, setIsBannerVisible] = useState(!isBannerHidden);

  const features = [
    t("plans_banner.features.screens"),
    t("plans_banner.features.notifications"),
    t("plans_banner.features.analysis"),
    t("plans_banner.features.sharing"),
    t("plans_banner.features.exports"),
  ];

  const handleCloseClick = () => {
    localStorage.setItem(LocalStorageKeys.TariffBannerHidden, "true");
    setIsBannerVisible(false);
  };

  const showBanner =
    currentUser?.credits !== undefined &&
    currentUser.credits < 1 &&
    location.pathname !== Routes.Plans &&
    isBannerVisible;

  if (!showBanner) {
    return null;
  }

  return (
    <Grid
      container
      flexDirection="column"
      borderRadius={1}
      width={199}
      overflow="hidden"
      sx={{ backgroundColor: "common.darkGray3" }}
    >
      <Grid sx={{ backgroundColor: "common.blue" }}>
        <img src={bannerImage} />
      </Grid>
      <Grid container flexDirection="column" px={3} pt={2} pb={3}>
        <Grid mb={3}>
          <Typography variant="body3" color="text.light">
            {t("plans_banner.description")}
          </Typography>
        </Grid>

        <Grid container flexDirection="column" mb={6} gap={2}>
          {features.map((feature, index) => (
            <Grid
              container
              alignItems="center"
              gap={1}
              key={`banner-feature-${index}`}
            >
              <Box component="img" src={checkIcon} />
              <Typography variant="body4" color="text.disabled">
                {feature}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Grid container justifyContent="space-between">
          <Link sx={{ color: "common.darkGray" }} onClick={handleCloseClick}>
            <Typography variant="body5">
              {t("plans_banner.close_button")}
            </Typography>
          </Link>
          <Link
            as={RouterLink}
            to={Routes.Plans}
            sx={{
              "&:hover": {
                opacity: 0.8,
              },
            }}
          >
            <Grid container component="span" alignItems="center" gap={2}>
              <Typography
                variant="body3"
                sx={{
                  background:
                    "linear-gradient(90deg, #5DFBE8 -1.83%, #8AA9FB 115.85%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                {t("plans_banner.view_button")}
              </Typography>
              <img src={arrowNext} width={10} height={8} />
            </Grid>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};
