import { Box } from "@flowriver/ui-kit";
import { FunctionComponent } from "react";

type Props = {
  active?: boolean;
};

export const ManyTokenIcon: FunctionComponent<Props> = ({ active = false }) => {
  return (
    <Box sx={{ ["path, g"]: { transition: "fill 0.1s ease" } }}>
      <svg
        width="160"
        height="63"
        viewBox="0 0 160 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2121_3303)">
          <g clipPath="url(#clip1_2121_3303)">
            <g clipPath="url(#clip2_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(0.444263 0.895896 0.895896 -0.444263 79.5273 18.2498)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M96.338 21.3993C97.5031 20.9737 98.5317 22.2883 97.8385 23.3169L96.1808 25.7765C95.9194 26.1642 96.0112 26.6892 96.3886 26.9653L112.87 39.0229C112.945 39.0775 116.226 41.3774 116.226 41.2849L105.847 10.9921C105.847 10.9557 105.829 10.9216 105.799 10.9008L105.326 1.59669L97.1453 13.5367C97.1987 13.4597 95.8796 15.36 95.8441 15.4112L89.6317 24.4042C89.5463 24.5274 89.6698 24.6875 89.8106 24.636L96.338 21.3993ZM88.2856 23.4705L94.2854 14.7837C94.9266 13.8599 98.4565 8.7988 99.5179 7.19854L103.914 2.47999L106.737 9.55754C107.202 9.8823 107.481 10.4122 107.485 10.9794L118.604 43.718C118.615 45.1574 113.065 41.195 111.903 40.3452L95.4213 28.2875C94.3335 27.4917 94.069 25.9785 94.8222 24.8608L95.8627 23.3171L90.3727 26.1749C88.7301 26.7749 87.2888 24.9074 88.2856 23.4705Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.67008"
              y="-0.225817"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(0.444263 0.895896 0.895896 -0.444263 79.3573 18.5239)"
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip3_2121_3303)">
            <g clipPath="url(#clip4_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(0.444263 0.895896 0.895896 -0.444263 80.9263 0.812866)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M97.7369 3.96242C98.902 3.53685 99.9306 4.85139 99.2374 5.87998L97.5797 8.33958C97.3184 8.72735 97.4102 9.25233 97.7876 9.52843L114.269 21.586C114.344 21.6406 117.625 23.9405 117.625 23.848L107.246 -6.44477C107.246 -6.48121 107.228 -6.51525 107.198 -6.53612L106.725 -15.8402L98.5442 -3.90017C98.5977 -3.97718 97.2785 -2.07684 97.243 -2.02568L91.0307 6.96734C90.9452 7.09051 91.0688 7.25058 91.2096 7.19915L97.7369 3.96242ZM89.6845 6.03357L95.6843 -2.65319C96.3256 -3.57699 99.8554 -8.63809 100.917 -10.2383L105.313 -14.9569L108.136 -7.87935C108.601 -7.55459 108.88 -7.02465 108.884 -6.45745L120.003 26.2811C120.014 27.7205 114.463 23.7581 113.302 22.9083L96.8202 10.8507C95.7324 10.0548 95.4679 8.54165 96.2212 7.42396L97.2616 5.88019L91.7717 8.738C90.129 9.33801 88.6878 7.47053 89.6845 6.03357Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.67008"
              y="-0.225817"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(0.444263 0.895896 0.895896 -0.444263 80.7562 1.08705)"
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip5_2121_3303)">
            <g clipPath="url(#clip6_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(0.444263 0.895896 0.895896 -0.444263 95.6533 10.2531)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M112.464 13.4026C113.629 12.977 114.658 14.2916 113.964 15.3202L112.307 17.7798C112.045 18.1675 112.137 18.6925 112.515 18.9686L128.996 31.0262C129.071 31.0808 132.352 33.3807 132.352 33.2882L121.973 2.99542C121.973 2.95898 121.955 2.92493 121.925 2.90407L121.452 -6.40002L113.271 5.54002C113.325 5.46301 112.006 7.36334 111.97 7.4145L105.758 16.4075C105.672 16.5307 105.796 16.6908 105.937 16.6393L112.464 13.4026ZM104.412 15.4738L110.411 6.787C111.053 5.86319 114.582 0.802096 115.644 -0.798161L120.04 -5.51671L122.863 1.56084C123.328 1.88559 123.607 2.41554 123.611 2.98274L134.73 35.7213C134.741 37.1606 129.191 33.1983 128.029 32.3485L111.547 20.2908C110.459 19.495 110.195 17.9818 110.948 16.8641L111.989 15.3204L106.499 18.1782C104.856 18.7782 103.415 16.9107 104.412 15.4738Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.67008"
              y="-0.225817"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(0.444263 0.895896 0.895896 -0.444263 95.4832 10.5272)"
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip7_2121_3303)">
            <g clipPath="url(#clip8_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(-0.452546 0.891741 0.891741 0.452546 128.3 -0.279663)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M135.826 15.0789C136.864 15.7585 136.425 17.3689 135.186 17.4282L132.223 17.5699C131.756 17.5922 131.39 17.9796 131.395 18.4472L131.583 38.8676C131.584 38.9601 131.702 42.9656 131.776 42.9097L149.853 16.4793C149.882 16.4573 149.899 16.4226 149.898 16.3861L157.073 10.4447L142.611 11.0254C142.705 11.0222 140.393 11.1009 140.331 11.103L129.408 11.4997C129.258 11.5049 129.204 11.6996 129.329 11.7817L135.826 15.0789ZM129.352 9.86241L139.902 9.4784C141.026 9.44012 147.193 9.24386 149.111 9.13796L155.521 9.84121L151.535 16.3349C151.553 16.9019 151.295 17.4423 150.843 17.7849L131.247 46.2706C130.1 47.1401 129.958 40.322 129.945 38.8827L129.756 18.4623C129.744 17.1145 130.799 15.9978 132.145 15.9334L134.005 15.8445L128.432 13.1523C126.968 12.1943 127.604 9.9225 129.352 9.86241Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.219598"
              y="-0.672144"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(-0.452546 0.891741 0.891741 0.452546 128.581 -0.451807)"
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip9_2121_3303)">
            <g clipPath="url(#clip10_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(-0.452546 0.891741 0.891741 0.452546 143.114 -9.58289)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M150.64 5.77572C151.678 6.45523 151.239 8.06569 150 8.12495L147.037 8.26665C146.57 8.28899 146.204 8.67642 146.209 9.14402L146.397 29.5644C146.398 29.6569 146.516 33.6624 146.59 33.6065L164.667 7.17606C164.696 7.15405 164.713 7.11933 164.712 7.08291L171.887 1.14152L157.425 1.72215C157.519 1.71897 155.207 1.79768 155.145 1.79982L144.222 2.19652C144.072 2.20167 144.018 2.39639 144.143 2.47851L150.64 5.77572ZM144.166 0.559191L154.716 0.175175C155.84 0.136902 162.007 -0.0593664 163.924 -0.165267L170.335 0.537991L166.349 7.03171C166.367 7.59867 166.109 8.13908 165.657 8.4817L146.061 36.9674C144.914 37.8368 144.772 31.0188 144.759 29.5795L144.57 9.15911C144.558 7.81132 145.613 6.69461 146.959 6.63022L148.818 6.54128L143.245 3.8491C141.782 2.89105 142.418 0.619276 144.166 0.559191Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.219598"
              y="-0.672144"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(-0.452546 0.891741 0.891741 0.452546 143.395 -9.75503)"
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip11_2121_3303)">
            <g clipPath="url(#clip12_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(-0.452546 0.891741 0.891741 0.452546 144.352 7.86621)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M151.877 23.2248C152.915 23.9043 152.476 25.5148 151.237 25.574L148.275 25.7157C147.807 25.7381 147.442 26.1255 147.446 26.5931L147.634 47.0135C147.635 47.106 147.753 51.1115 147.827 51.0556L165.905 24.6252C165.934 24.6031 165.95 24.5684 165.949 24.532L173.125 18.5906L158.663 19.1713C158.756 19.1681 156.444 19.2468 156.382 19.2489L145.459 19.6456C145.309 19.6508 145.255 19.8455 145.38 19.9276L151.877 23.2248ZM145.403 18.0083L155.953 17.6243C157.077 17.586 163.244 17.3897 165.162 17.2838L171.573 17.9871L167.587 24.4808C167.604 25.0478 167.346 25.5882 166.894 25.9308L147.298 54.4165C146.151 55.2859 146.009 48.4679 145.996 47.0286L145.808 26.6082C145.795 25.2604 146.85 24.1437 148.196 24.0793L150.056 23.9904L144.483 21.2982C143.02 20.3401 143.655 18.0684 145.403 18.0083Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.219598"
              y="-0.672144"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(-0.452546 0.891741 0.891741 0.452546 144.632 7.69407)"
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip13_2121_3303)">
            <g clipPath="url(#clip14_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(0.993554 0.113363 0.113363 -0.993554 139.01 59.5494)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M150.783 47.1424C151.058 45.933 152.72 45.7829 153.208 46.9235L154.373 49.651C154.557 50.081 155.047 50.289 155.484 50.122L174.558 42.8283C174.645 42.7952 178.358 41.2883 178.28 41.2387L147.206 33.5065C147.175 33.487 147.137 33.4836 147.103 33.4973L139.033 28.8425L144.618 42.1954C144.583 42.1087 145.462 44.2483 145.486 44.3058L149.665 54.4056C149.722 54.5442 149.923 54.5274 149.957 54.3812L150.783 47.1424ZM148.15 55.029L144.113 45.2741C143.685 44.234 141.351 38.5218 140.584 36.7616L139.009 30.5076L146.484 31.9803C147.01 31.7661 147.606 31.8196 148.085 32.1239L181.614 40.5628C182.829 41.3349 176.488 43.8444 175.144 44.3585L156.069 51.6522C154.81 52.1336 153.396 51.5342 152.866 50.2947L152.135 48.5828L151.554 54.7447C151.166 56.45 148.815 56.6463 148.15 55.029Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.553458"
              y="0.440095"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(0.993554 0.113363 0.113363 -0.993554 138.957 60.4895)"
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip15_2121_3303)">
            <g clipPath="url(#clip16_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(0.993554 0.113363 0.113363 -0.993554 125.127 48.9073)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M136.9 36.5003C137.175 35.2909 138.837 35.1408 139.325 36.2814L140.49 39.0089C140.674 39.439 141.165 39.6469 141.601 39.4799L160.676 32.1862C160.762 32.1532 164.475 30.6462 164.397 30.5966L133.323 22.8645C133.292 22.8449 133.254 22.8415 133.22 22.8552L125.151 18.2004L130.735 31.5533C130.7 31.4666 131.579 33.6062 131.603 33.6637L135.782 43.7635C135.839 43.9021 136.04 43.8853 136.074 43.7391L136.9 36.5003ZM134.267 44.3869L130.23 34.632C129.802 33.5919 127.469 27.8797 126.701 26.1195L125.126 19.8655L132.602 21.3383C133.127 21.124 133.723 21.1776 134.202 21.4818L167.731 29.9207C168.946 30.6928 162.605 33.2023 161.261 33.7164L142.186 41.0101C140.927 41.4915 139.513 40.8921 138.984 39.6527L138.252 37.9407L137.671 44.1026C137.283 45.8079 134.932 46.0042 134.267 44.3869Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.553458"
              y="0.440095"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(0.993554 0.113363 0.113363 -0.993554 125.074 49.8474)"
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip17_2121_3303)">
            <g clipPath="url(#clip18_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(0.993554 0.113363 0.113363 -0.993554 141.051 41.6656)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M152.823 29.2586C153.098 28.0492 154.761 27.8991 155.248 29.0397L156.413 31.7672C156.597 32.1973 157.088 32.4052 157.525 32.2382L176.599 24.9445C176.685 24.9115 180.398 23.4045 180.32 23.3549L149.246 15.6228C149.216 15.6032 149.177 15.5998 149.144 15.6135L141.074 10.9587L146.659 24.3116C146.623 24.2249 147.503 26.3645 147.526 26.422L151.705 36.5218C151.762 36.6604 151.964 36.6436 151.997 36.4974L152.823 29.2586ZM150.19 37.1452L146.153 27.3903C145.725 26.3502 143.392 20.638 142.624 18.8778L141.049 12.6238L148.525 14.0966C149.05 13.8823 149.647 13.9359 150.125 14.2401L183.655 22.679C184.87 23.4511 178.529 25.9606 177.184 26.4747L158.11 33.7684C156.851 34.2498 155.436 33.6504 154.907 32.411L154.175 30.699L153.594 36.8609C153.206 38.5662 150.856 38.7625 150.19 37.1452Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.553458"
              y="0.440095"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(0.993554 0.113363 0.113363 -0.993554 140.997 42.6057)"
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip19_2121_3303)">
            <g clipPath="url(#clip20_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(0.993554 0.113363 0.113363 -0.993554 113.01 54.5494)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M124.783 42.1424C125.058 40.933 126.72 40.7829 127.208 41.9235L128.373 44.651C128.557 45.081 129.047 45.289 129.484 45.122L148.558 37.8283C148.645 37.7952 152.358 36.2883 152.28 36.2387L121.206 28.5065C121.175 28.487 121.137 28.4836 121.103 28.4973L113.033 23.8425L118.618 37.1954C118.583 37.1087 119.462 39.2483 119.486 39.3058L123.665 49.4056C123.722 49.5442 123.923 49.5274 123.957 49.3812L124.783 42.1424ZM122.15 50.029L118.113 40.2741C117.685 39.234 115.351 33.5218 114.584 31.7616L113.009 25.5076L120.484 26.9803C121.01 26.7661 121.606 26.8196 122.085 27.1239L155.614 35.5628C156.829 36.3349 150.488 38.8444 149.144 39.3585L130.069 46.6522C128.81 47.1336 127.396 46.5342 126.866 45.2947L126.135 43.5828L125.554 49.7447C125.166 51.45 122.815 51.6463 122.15 50.029Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.553458"
              y="0.440095"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(0.993554 0.113363 0.113363 -0.993554 112.957 55.4895)"
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip21_2121_3303)">
            <g clipPath="url(#clip22_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(0.993554 0.113363 0.113363 -0.993554 99.1274 43.9073)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M110.9 31.5003C111.175 30.2909 112.837 30.1408 113.325 31.2814L114.49 34.0089C114.674 34.439 115.165 34.6469 115.601 34.4799L134.676 27.1862C134.762 27.1532 138.475 25.6462 138.397 25.5966L107.323 17.8645C107.292 17.8449 107.254 17.8415 107.22 17.8552L99.1505 13.2004L104.735 26.5533C104.7 26.4666 105.579 28.6062 105.603 28.6637L109.782 38.7635C109.839 38.9021 110.04 38.8853 110.074 38.7391L110.9 31.5003ZM108.267 39.3869L104.23 29.632C103.802 28.5919 101.469 22.8797 100.701 21.1195L99.1258 14.8655L106.602 16.3383C107.127 16.124 107.723 16.1776 108.202 16.4818L141.731 24.9207C142.946 25.6928 136.605 28.2023 135.261 28.7164L116.186 36.0101C114.927 36.4915 113.513 35.8921 112.984 34.6527L112.252 32.9407L111.671 39.1026C111.283 40.8079 108.932 41.0042 108.267 39.3869Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.553458"
              y="0.440095"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(0.993554 0.113363 0.113363 -0.993554 99.074 44.8474)"
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip23_2121_3303)">
            <g clipPath="url(#clip24_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(-0.477862 0.878435 0.878435 0.477862 122.82 12.0643)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M129.904 27.6317C130.922 28.3406 130.437 29.9379 129.197 29.9617L126.232 30.0186C125.764 30.0275 125.387 30.4044 125.378 30.8719L124.982 51.2893C124.981 51.3818 124.984 55.389 125.06 55.3353L143.886 29.4326C143.915 29.4114 143.933 29.3772 143.933 29.3407L151.275 23.607L136.803 23.7738C136.896 23.7733 134.583 23.7859 134.521 23.7862L123.591 23.8704C123.441 23.8713 123.381 24.0644 123.504 24.15L129.904 27.6317ZM123.582 22.2321L134.139 22.15C135.263 22.1439 141.433 22.1241 143.353 22.073L149.741 22.9593L145.571 29.3364C145.573 29.9036 145.299 30.4364 144.838 30.766L124.435 58.6797C123.263 59.5159 123.316 52.6967 123.344 51.2575L123.74 30.8401C123.766 29.4925 124.853 28.4064 126.2 28.3806L128.061 28.3448L122.568 25.4944C121.133 24.4949 121.833 22.2422 123.582 22.2321Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.200287"
              y="-0.678148"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(-0.477862 0.878435 0.878435 0.477862 123.12 11.8862)"
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip25_2121_3303)">
            <g clipPath="url(#clip26_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(-0.477862 0.878435 0.878435 0.477862 116.869 -10.6437)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M123.953 4.92368C124.971 5.63259 124.486 7.22984 123.246 7.25365L120.28 7.31057C119.813 7.31954 119.436 7.69634 119.427 8.16387L119.031 28.5813C119.029 28.6738 119.033 32.681 119.108 32.6273L137.935 6.72459C137.964 6.70342 137.982 6.66918 137.982 6.63274L145.324 0.898985L130.851 1.06581C130.945 1.0653 128.632 1.07787 128.57 1.07823L117.64 1.16241C117.49 1.16327 117.43 1.35636 117.553 1.44203L123.953 4.92368ZM117.63 -0.475862L128.187 -0.558016C129.312 -0.564133 135.482 -0.583955 137.402 -0.634982L143.79 0.25132L139.62 6.62839C139.621 7.19563 139.348 7.72844 138.886 8.05799L118.484 35.9717C117.312 36.8079 117.365 29.9886 117.393 28.5495L117.789 8.13212C117.815 6.78452 118.901 5.69843 120.249 5.67256L122.11 5.63684L116.617 2.78639C115.181 1.78689 115.882 -0.465783 117.63 -0.475862Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.200287"
              y="-0.678148"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(-0.477862 0.878435 0.878435 0.477862 117.169 -10.8218)"
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip27_2121_3303)">
            <g clipPath="url(#clip28_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(-0.567226 0.823562 0.823562 0.567226 56.8696 1.74414)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M62.2841 17.9679C63.2221 18.7795 62.5729 20.3172 61.3371 20.211L58.3819 19.9571C57.916 19.917 57.5017 20.2523 57.4438 20.7163L54.912 40.98C54.9005 41.0718 54.4846 45.0574 54.565 45.0118L76.0003 21.2231C76.032 21.2051 76.053 21.1729 76.0568 21.1367L83.9594 16.2034L69.5487 14.8536C69.642 14.863 67.3401 14.6332 67.2781 14.627L56.3996 13.5662C56.2504 13.5513 56.1705 13.7371 56.2839 13.8351L62.2841 17.9679ZM56.5618 11.9359L67.0693 12.9598C68.1883 13.0714 74.3269 13.6979 76.2413 13.8482L82.5015 15.3986L77.6865 21.3039C77.6286 21.8682 77.3009 22.3694 76.8073 22.6488L53.5937 48.2724C52.3411 48.9814 53.1079 42.2052 53.2863 40.7769L55.8181 20.5132C55.9852 19.1757 57.1793 18.2094 58.5222 18.3248L60.377 18.4842L55.2119 15.0741C53.8894 13.9298 54.8215 11.7628 56.5618 11.9359Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.128168"
              y="-0.695394"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(-0.567226 0.823562 0.823562 0.567226 57.2415 1.54875)"
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip29_2121_3303)">
            <g clipPath="url(#clip30_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(-0.567226 0.823562 0.823562 0.567226 72.79 -5.50427)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M78.2045 10.7195C79.1425 11.5311 78.4933 13.0688 77.2575 12.9626L74.3023 12.7086C73.8364 12.6686 73.4221 13.0039 73.3642 13.4679L70.8324 33.7316C70.8209 33.8234 70.405 37.8089 70.4854 37.7634L91.9207 13.9747C91.9524 13.9567 91.9735 13.9245 91.9772 13.8883L99.8798 8.95496L85.4691 7.60524C85.5624 7.61455 83.2605 7.38479 83.1985 7.37863L72.32 6.31775C72.1708 6.30291 72.0909 6.48866 72.2043 6.58674L78.2045 10.7195ZM72.4822 4.6875L82.9897 5.71135C84.1087 5.82303 90.2473 6.4495 92.1617 6.59978L98.4219 8.15019L93.6069 14.0555C93.549 14.6198 93.2213 15.121 92.7277 15.4004L69.5141 41.024C68.2615 41.733 69.0283 34.9567 69.2067 33.5285L71.7385 13.2648C71.9056 11.9273 73.0997 10.961 74.4426 11.0764L76.2974 11.2357L71.1323 7.82565C69.8098 6.68137 70.7419 4.51439 72.4822 4.6875Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.128168"
              y="-0.695394"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(-0.567226 0.823562 0.823562 0.567226 73.1619 -5.69967)"
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip31_2121_3303)">
            <g clipPath="url(#clip32_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(-0.567226 0.823562 0.823562 0.567226 71.6938 11.9542)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M77.1083 28.178C78.0464 28.9896 77.3971 30.5273 76.1613 30.4211L73.2061 30.1671C72.7402 30.1271 72.3259 30.4624 72.268 30.9264L69.7362 51.1901C69.7247 51.2818 69.3088 55.2674 69.3893 55.2219L90.8245 31.4332C90.8562 31.4152 90.8773 31.383 90.881 31.3468L98.7836 26.4135L84.3729 25.0637C84.4662 25.073 82.1643 24.8433 82.1024 24.8371L71.2238 23.7762C71.0747 23.7614 70.9948 23.9472 71.1081 24.0452L77.1083 28.178ZM71.386 22.146L81.8935 23.1698C83.0125 23.2815 89.1511 23.908 91.0655 24.0583L97.3257 25.6087L92.5107 31.514C92.4528 32.0783 92.1251 32.5795 91.6315 32.8589L68.4179 58.4825C67.1653 59.1915 67.9321 52.4152 68.1105 50.987L70.6423 30.7233C70.8094 29.3858 72.0035 28.4195 73.3464 28.5349L75.2012 28.6942L70.0362 25.2841C68.7136 24.1399 69.6457 21.9729 71.386 22.146Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.128168"
              y="-0.695394"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(-0.567226 0.823562 0.823562 0.567226 72.0657 11.7588)"
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip33_2121_3303)">
            <g clipPath="url(#clip34_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(4.37114e-08 1 1 -4.37114e-08 24 33)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37.6616 43.2902C38.8944 43.4265 39.232 45.0612 38.154 45.6747L35.5761 47.1418C35.1697 47.3731 35.0187 47.8842 35.2342 48.2992L44.6432 66.4237C44.6858 66.5058 46.604 70.0241 46.6444 69.941L50.8041 38.1909C50.82 38.1581 50.8191 38.1196 50.8016 38.0877L54.5116 29.5422L41.8779 36.6048C41.96 36.5595 39.9339 37.676 39.8794 37.706L30.3185 43.0029C30.1873 43.0753 30.2268 43.2736 30.3758 43.2901L37.6616 43.2902ZM29.5274 41.5683L38.7617 36.4514C39.7467 35.9086 45.1575 32.9426 46.8194 31.9805L52.8545 29.7064L52.2387 37.301C52.511 37.7985 52.5255 38.3972 52.2774 38.9073L47.694 73.1773C47.0645 74.4717 43.8524 68.4561 43.1892 67.1786L33.7801 49.0541C33.1591 47.8578 33.5944 46.3846 34.7658 45.718L36.3838 44.7971L30.1957 44.9185C28.4575 44.7262 27.996 42.4129 29.5274 41.5683Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.5"
              y="-0.5"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(4.37114e-08 1 1 -4.37114e-08 24 33)"
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip35_2121_3303)">
            <g clipPath="url(#clip36_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(4.37114e-08 1 1 -4.37114e-08 31 -1)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44.6616 9.29016C45.8944 9.4265 46.232 11.0612 45.154 11.6747L42.5761 13.1418C42.1697 13.3731 42.0187 13.8842 42.2342 14.2992L51.6432 32.4237C51.6858 32.5058 53.604 36.0241 53.6444 35.941L57.8041 4.19086C57.82 4.15808 57.8191 4.11962 57.8016 4.08765L61.5116 -4.45782L48.8779 2.60476C48.96 2.55951 46.9339 3.67597 46.8794 3.70604L37.3185 9.00293C37.1873 9.07532 37.2268 9.27361 37.3758 9.29009L44.6616 9.29016ZM36.5274 7.56833L45.7617 2.45138C46.7467 1.90863 52.1575 -1.0574 53.8194 -2.01952L59.8545 -4.29363L59.2387 3.30096C59.511 3.79852 59.5255 4.3972 59.2774 4.90731L54.694 39.1773C54.0645 40.4717 50.8524 34.4561 50.1892 33.1786L40.7801 15.0541C40.1591 13.8578 40.5944 12.3846 41.7658 11.718L43.3838 10.7971L37.1957 10.9185C35.4575 10.7262 34.996 8.41287 36.5274 7.56833Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.5"
              y="-0.5"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(4.37114e-08 1 1 -4.37114e-08 31 -1)"
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip37_2121_3303)">
            <g clipPath="url(#clip38_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(4.37114e-08 1 1 -4.37114e-08 45 27)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M58.6616 37.2902C59.8944 37.4265 60.232 39.0612 59.154 39.6747L56.5761 41.1418C56.1697 41.3731 56.0187 41.8842 56.2342 42.2992L65.6432 60.4237C65.6858 60.5058 67.604 64.0241 67.6444 63.941L71.8041 32.1909C71.82 32.1581 71.8191 32.1196 71.8016 32.0877L75.5116 23.5422L62.8779 30.6048C62.96 30.5595 60.9339 31.676 60.8794 31.706L51.3185 37.0029C51.1873 37.0753 51.2268 37.2736 51.3758 37.2901L58.6616 37.2902ZM50.5274 35.5683L59.7617 30.4514C60.7467 29.9086 66.1575 26.9426 67.8194 25.9805L73.8545 23.7064L73.2387 31.301C73.511 31.7985 73.5255 32.3972 73.2774 32.9073L68.694 67.1773C68.0645 68.4717 64.8524 62.4561 64.1892 61.1786L54.7801 43.0541C54.1591 41.8578 54.5944 40.3846 55.7658 39.718L57.3838 38.7971L51.1957 38.9185C49.4575 38.7262 48.996 36.4129 50.5274 35.5683Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.5"
              y="-0.5"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(4.37114e-08 1 1 -4.37114e-08 45 27)"
              stroke="white"
            />
          </g>
          <g clipPath="url(#clip39_2121_3303)">
            <g clipPath="url(#clip40_2121_3303)">
              <rect
                width="22"
                height="22"
                rx="11"
                transform="matrix(-0.357759 0.933814 0.933814 0.357759 17.1626 -12.7084)"
                fill={active ? "#0E0F0F" : "#2D74DF"}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.2387 1.78816C27.3412 2.35655 27.0716 4.00379 25.8454 4.19104L22.9133 4.63881C22.4511 4.7094 22.1272 5.13265 22.1799 5.59729L24.482 25.8884C24.4924 25.9803 25.0249 29.952 25.0924 29.8888L40.3357 1.72821C40.3623 1.70331 40.3752 1.66706 40.3703 1.63095L46.892 -5.02163L32.5677 -2.94634C32.6606 -2.95921 30.3692 -2.64148 30.3075 -2.63291L19.4844 -1.1071C19.3359 -1.08646 19.3019 -0.887142 19.4352 -0.818453L26.2387 1.78816ZM19.2589 -2.72979L29.7127 -4.2044C30.8266 -4.35886 36.9404 -5.1928 38.8365 -5.4967L45.2858 -5.46118L41.9937 1.41044C42.07 1.97252 41.8693 2.53675 41.4552 2.92434L24.9147 33.2863C23.8638 34.2699 23.0164 27.5033 22.8542 26.0731L20.5521 5.78197C20.4001 4.44271 21.3336 3.22276 22.666 3.01928L24.5063 2.73824L18.6844 0.637715C17.13 -0.163661 17.5267 -2.48902 19.2589 -2.72979Z"
                fill={active ? "#fff" : "#0E0F0F"}
              />
            </g>
            <rect
              x="-0.288028"
              y="-0.645786"
              width="23"
              height="23"
              rx="11.5"
              transform="matrix(-0.357759 0.933814 0.933814 0.357759 17.3746 -12.8542)"
              stroke="white"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_2121_3303">
            <rect width="160" height="63" fill="white" />
          </clipPath>
          <clipPath id="clip1_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(78.187 17.7981) rotate(-26.3762)"
            />
          </clipPath>
          <clipPath id="clip2_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(0.444263 0.895896 0.895896 -0.444263 79.5273 18.2498)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip3_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(79.5859 0.361206) rotate(-26.3762)"
            />
          </clipPath>
          <clipPath id="clip4_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(0.444263 0.895896 0.895896 -0.444263 80.9263 0.812866)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip5_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(94.313 9.80139) rotate(-26.3762)"
            />
          </clipPath>
          <clipPath id="clip6_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(0.444263 0.895896 0.895896 -0.444263 95.6533 10.2531)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip7_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(127.861 -1.6239) rotate(26.9072)"
            />
          </clipPath>
          <clipPath id="clip8_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(-0.452546 0.891741 0.891741 0.452546 128.3 -0.279663)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip9_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(142.675 -10.9271) rotate(26.9072)"
            />
          </clipPath>
          <clipPath id="clip10_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(-0.452546 0.891741 0.891741 0.452546 143.114 -9.58289)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip11_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(143.913 6.52197) rotate(26.9072)"
            />
          </clipPath>
          <clipPath id="clip12_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(-0.452546 0.891741 0.891741 0.452546 144.352 7.86621)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip13_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(137.903 60.4297) rotate(-83.4908)"
            />
          </clipPath>
          <clipPath id="clip14_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(0.993554 0.113363 0.113363 -0.993554 139.01 59.5494)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip15_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(124.021 49.7876) rotate(-83.4908)"
            />
          </clipPath>
          <clipPath id="clip16_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(0.993554 0.113363 0.113363 -0.993554 125.127 48.9073)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip17_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(139.944 42.5459) rotate(-83.4908)"
            />
          </clipPath>
          <clipPath id="clip18_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(0.993554 0.113363 0.113363 -0.993554 141.051 41.6656)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip19_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(111.903 55.4297) rotate(-83.4908)"
            />
          </clipPath>
          <clipPath id="clip20_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(0.993554 0.113363 0.113363 -0.993554 113.01 54.5494)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip21_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(98.0205 44.7876) rotate(-83.4908)"
            />
          </clipPath>
          <clipPath id="clip22_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(0.993554 0.113363 0.113363 -0.993554 99.1274 43.9073)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip23_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(122.42 10.708) rotate(28.5459)"
            />
          </clipPath>
          <clipPath id="clip24_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(-0.477862 0.878435 0.878435 0.477862 122.82 12.0643)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip25_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(116.469 -12) rotate(28.5459)"
            />
          </clipPath>
          <clipPath id="clip26_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(-0.477862 0.878435 0.878435 0.477862 116.869 -10.6437)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip27_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(56.6133 0.353394) rotate(34.557)"
            />
          </clipPath>
          <clipPath id="clip28_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(-0.567226 0.823562 0.823562 0.567226 56.8696 1.74414)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip29_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(72.5337 -6.89502) rotate(34.557)"
            />
          </clipPath>
          <clipPath id="clip30_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(-0.567226 0.823562 0.823562 0.567226 72.79 -5.50427)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip31_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(71.4375 10.5635) rotate(34.557)"
            />
          </clipPath>
          <clipPath id="clip32_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(-0.567226 0.823562 0.823562 0.567226 71.6938 11.9542)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip33_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(23 32)"
            />
          </clipPath>
          <clipPath id="clip34_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(4.37114e-08 1 1 -4.37114e-08 24 33)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip35_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(30 -2)"
            />
          </clipPath>
          <clipPath id="clip36_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(4.37114e-08 1 1 -4.37114e-08 31 -1)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip37_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(44 26)"
            />
          </clipPath>
          <clipPath id="clip38_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(4.37114e-08 1 1 -4.37114e-08 45 27)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip39_2121_3303">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(16.5864 -14) rotate(20.9626)"
            />
          </clipPath>
          <clipPath id="clip40_2121_3303">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(-0.357759 0.933814 0.933814 0.357759 17.1626 -12.7084)"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};
