import { TypographyType } from "..";
export const typography = {
    fontFamily: "Figtree, Space Grotesk, sans-serif",
    fontSize: 14,
    htmlFontSize: 16,
    fontWeightBold: 700,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightLight: 300,
    [TypographyType.H1]: {
        fontSize: "60px",
        lineHeight: "normal",
        fontWeight: 600,
        fontFamily: "Figtree, Space Grotesk, sans-serif",
    },
    [TypographyType.H2]: {
        fontSize: "36px",
        lineHeight: "normal",
        fontWeight: 600,
        fontFamily: "Figtree, Space Grotesk, sans-serif",
    },
    [TypographyType.H3]: {
        fontSize: "24px",
        lineHeight: "normal",
        fontWeight: 600,
        fontFamily: "Figtree, Space Grotesk, sans-serif",
    },
    [TypographyType.Title1]: {
        fontSize: "20px",
        lineHeight: "normal",
        fontWeight: 600,
        fontFamily: "Figtree, Space Grotesk, sans-serif",
    },
    [TypographyType.Title2]: {
        fontSize: "18px",
        lineHeight: "normal",
        fontWeight: 700,
        fontFamily: "Figtree, Space Grotesk, sans-serif",
    },
    [TypographyType.Title3]: {
        fontSize: "18px",
        lineHeight: "normal",
        fontWeight: 500,
        fontFamily: "Figtree, Space Grotesk, sans-serif",
    },
    [TypographyType.Subtitle1]: {
        fontSize: "16px",
        lineHeight: "normal",
        fontWeight: 700,
        fontFamily: "Figtree, Space Grotesk, sans-serif",
    },
    [TypographyType.Subtitle2]: {
        fontSize: "16px",
        lineHeight: "normal",
        fontWeight: 500,
        fontFamily: "Figtree, Space Grotesk, sans-serif",
    },
    [TypographyType.Subtitle3]: {
        fontSize: "14px",
        lineHeight: "normal",
        fontWeight: 700,
        fontFamily: "Figtree, Space Grotesk, sans-serif",
    },
    [TypographyType.Body1]: {
        fontSize: "14px",
        lineHeight: "normal",
        fontWeight: 500,
        fontFamily: "Figtree, Space Grotesk, sans-serif",
    },
    [TypographyType.Body2]: {
        fontSize: "12px",
        lineHeight: "normal",
        fontWeight: 600,
        fontFamily: "Figtree, Space Grotesk, sans-serif",
    },
    [TypographyType.Body3]: {
        fontSize: "12px",
        lineHeight: "normal",
        fontWeight: 500,
        fontFamily: "Figtree, Space Grotesk, sans-serif",
    },
    [TypographyType.Body4]: {
        fontSize: "10px",
        lineHeight: "normal",
        fontWeight: 600,
        fontFamily: "Figtree, Space Grotesk, sans-serif",
    },
    [TypographyType.Body5]: {
        fontSize: "10px",
        lineHeight: "normal",
        fontWeight: 500,
        fontFamily: "Figtree, Space Grotesk, sans-serif",
    },
};
