const LS_KEY_PREFIX = "FR_";

export const addLSPrefix = (key: string) => `${LS_KEY_PREFIX}${key}`;

export const useLocalStorage = () => {
  const setItem = (key: string, value: string): void => {
    localStorage.setItem(addLSPrefix(key), value);
  };

  const getItem = (key: string): string | null => {
    return localStorage.getItem(addLSPrefix(key));
  };

  const removeItem = (key: string): void => {
    localStorage.removeItem(addLSPrefix(key));
  };

  return {
    setItem,
    getItem,
    removeItem,
  };
};
