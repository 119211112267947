import { darkTheme, lightTheme, ThemeProvider } from "@flowriver/ui-kit";
import { FC, PropsWithChildren } from "react";
import { useSettingsStore } from "src/hooks/useSettingsStore";

const themes = {
  light: lightTheme,
  dark: darkTheme,
};

export const ThemeSwitchProvider: FC<PropsWithChildren> = ({ children }) => {
  const { theme } = useSettingsStore();

  return <ThemeProvider theme={themes[theme]}>{children}</ThemeProvider>;
};
