import { Button as BaseButton, buttonClasses } from "@mui/base";
import { css, styled } from "@mui/material";
import { TypographyType } from "./constants";
import { useTypographyStyles } from "./helpers/useTypographyStyles";
export const Button = styled(BaseButton)(({ theme, variant = "contained", size = "medium", color = "primary" }) => css `
    font-size: ${theme.typography.subtitle3.fontSize};
    line-height: ${theme.typography.subtitle3.lineHeight};
    font-weight: ${theme.typography.subtitle3.fontWeight};

    cursor: pointer;
    color: ${theme.palette.text.header};
    display: inline-flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    z-index: 0;

    transition: background-color 0.3s ease;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: ${theme.palette.primary.gradient};
      opacity: 0;
      transition:
        opacity 0.3s ease,
        color 0.3s ease;
      z-index: -1;
    }

    &:hover {
      background-color: transparent;
      color: ${theme.palette.dark.contrastText};
    }

    &:hover:after {
      opacity: 1;
    }

    &:active::after {
      opacity: 0;
    }

    ${color === "primary" &&
    css `
      border: 1px solid ${theme.palette.primary.main};
      box-shadow: 3px 3px 0px 0px ${theme.palette.dark.main};
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};

      &:hover {
        background-color: transparent;
        border-color: ${theme.palette.primary.dark};
        color: ${theme.palette.dark.main};
      }

      &:after {
        background-image: ${theme.palette.primary.gradient};
      }

      &.${buttonClasses.root}:active, &.${buttonClasses.active} {
        background-color: ${theme.palette.dark.main};
        border-color: ${theme.palette.dark.contrastText};
        color: ${theme.palette.dark.contrastText};
      }

      &.${buttonClasses.disabled},
        &.${buttonClasses.disabled}:hover,
        &.${buttonClasses.disabled}:active {
        background-color: ${theme.palette.primary.disabled};
        color: ${theme.palette.action.disabled};
        box-shadow: none;
        border: none;
      }
    `}

    ${color === "secondary" &&
    css `
      background-color: ${theme.palette.secondary.main};
      box-shadow: 3px 3px 0px 0px ${theme.palette.dark.main};
      border: 1px solid ${theme.palette.dark.main};

      &:hover {
        border-color: ${theme.palette.secondary.dark};
        color: ${theme.palette.dark.main};
        background-color: ${theme.palette.secondary.main};
      }

      &:after {
        background-image: ${theme.palette.secondary.gradient};
      }

      ${buttonClasses.root}:active, &.${buttonClasses.active} {
        background-color: ${theme.palette.secondary.dark};
        border-color: ${theme.palette.secondary.dark};
        color: ${theme.palette.dark.contrastText};
      }

      &.${buttonClasses.disabled},
        &.${buttonClasses.disabled}:hover,
        &.${buttonClasses.disabled}:active {
        background-color: ${theme.palette.secondary.disabled};
        color: ${theme.palette.action.disabled};
        box-shadow: none;
        border: 1px solid ${theme.palette.divider};

        &:after {
          background-image: none;
        }
      }

      ${variant === "rounded" &&
        css `
        border-color: ${theme.palette.divider};
      `}
    `}

    ${color === "dark" &&
    css `
      background-color: ${theme.palette.dark.main};
      color: ${theme.palette.dark.contrastText};
      box-shadow: 3px 3px 0px 0px #000000;
      border: 1px solid ${theme.palette.dark.main};

      &:hover {
        border-color: ${theme.palette.secondary.dark};
        color: ${theme.palette.dark.main};
        background-color: ${theme.palette.secondary.main};
      }

      &:after {
        background-image: ${theme.palette.secondary.gradient};
      }

      &.${buttonClasses.disabled},
        &.${buttonClasses.disabled}:hover,
        &.${buttonClasses.disabled}:active {
        background-color: ${theme.palette.dark.disabled};
        color: ${theme.palette.divider};
        box-shadow: none;
        border: 1px solid ${theme.palette.divider};

        &:after {
          background-image: none;
        }
      }

      :visited {
        color: ${theme.palette.dark.contrastText};
      }
    `}

    ${color === "light" &&
    css `
      background-color: ${theme.palette.common.white};
      color: ${theme.palette.text.dark};
      box-shadow: 3px 3px 0px 0px ${theme.palette.dark.main};
      border: 1px solid ${theme.palette.dark.main};

      &:hover {
        border-color: ${theme.palette.secondary.dark};
        color: ${theme.palette.dark.main};
        background-color: ${theme.palette.secondary.main};
      }

      &:active {
        background-color: ${theme.palette.secondary.dark};
        border-color: ${theme.palette.secondary.dark};
        color: ${theme.palette.dark.contrastText};
      }

      &.${buttonClasses.disabled},
        &.${buttonClasses.disabled}:hover,
        &.${buttonClasses.disabled}:active {
        background-color: ${theme.palette.common.softGray};
        color: ${theme.palette.common.darkGray};
        box-shadow: none;
        border: 1px solid ${theme.palette.common.gray};

        &:after {
          background-image: none;
        }
      }
    `}

      ${variant === "contained" &&
    css `
      ${size === "small" &&
        css `
        padding: ${theme.spacing(1, 2)};
        font-size: ${theme.typography.body5.fontSize};
        line-height: ${theme.typography.body5.lineHeight};
        font-weight: ${theme.typography.body5.fontWeight};
      `}

      ${size === "medium" &&
        css `
        padding: ${theme.spacing(2, 4)};
        font-size: ${theme.typography.subtitle3.fontSize};
        line-height: ${theme.typography.subtitle3.lineHeight};
        font-weight: ${theme.typography.subtitle3.fontWeight};
      `}

      ${size === "large" &&
        css `
        padding: ${theme.spacing(4, 4)};
        ${useTypographyStyles(TypographyType.Subtitle1)}
      `}

        ${size === "huge" &&
        css `
        padding: ${theme.spacing(18, 13)};
        font-size: ${theme.typography.title1.fontSize};
        line-height: ${theme.typography.title1.lineHeight};
        font-weight: ${theme.typography.title1.fontWeight};
      `}
    `}

      ${variant === "rounded" &&
    css `
      ${size === "small" &&
        css `
        border-radius: ${theme.spacing(1)};
        padding: ${theme.spacing(1, 2)};
        font-size: ${theme.typography.body5.fontSize};
        line-height: ${theme.typography.body5.lineHeight};
        font-weight: ${theme.typography.body5.fontWeight};
        box-shadow: none;
      `}

      ${size === "medium" &&
        css `
        border-radius: ${theme.spacing(27)};
        padding: ${theme.spacing(2, 4)};
        font-size: ${theme.typography.body1.fontSize};
        line-height: ${theme.typography.body1.lineHeight};
        font-weight: ${theme.typography.body1.fontWeight};
        box-shadow: none;
      `}

        ${size === "huge" &&
        css `
        border-radius: ${theme.spacing(2)};
        padding: ${theme.spacing(18, 13)};
        font-size: ${theme.typography.title1.fontSize};
        line-height: ${theme.typography.title1.lineHeight};
        font-weight: ${theme.typography.title1.fontWeight};
        box-shadow: none;
      `}
    `}

      ${variant === "flat" &&
    css `
      ${size === "small" &&
        css `
        padding: ${theme.spacing(1, 2)};
        font-size: ${theme.typography.body5.fontSize};
        line-height: ${theme.typography.body5.lineHeight};
        font-weight: ${theme.typography.body5.fontWeight};
      `}

      ${size === "medium" &&
        css `
        padding: ${theme.spacing(2, 4)};
        font-size: ${theme.typography.subtitle3.fontSize};
        line-height: ${theme.typography.subtitle3.lineHeight};
        font-weight: ${theme.typography.subtitle3.fontWeight};
      `}
    `}

      ${variant === "outlined" &&
    css `
      ${size === "medium" &&
        css `
        padding: ${theme.spacing(1, 4)};
        font-size: ${theme.typography.body5.fontSize};
        line-height: ${theme.typography.body5.lineHeight};
        font-weight: ${theme.typography.body5.fontWeight};
        border-radius: 0;
      `}
    `}

    &.${buttonClasses.disabled} {
      cursor: not-allowed;
    }
  `);
