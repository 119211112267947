import { ThemeMode } from "@flowriver/ui-kit";
import { useLocalStorage as getLocalStorage } from "src/hooks/useLocalStorage";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export type State = {
  theme: ThemeMode;
  setTheme: (theme: ThemeMode) => void;
};

const defaultState = {
  theme: ThemeMode.light,
};

export const useSettingsStore = create<State>()(
  persist(
    devtools(
      (set) => ({
        ...defaultState,
        setTheme: (theme: ThemeMode) => set({ theme }),
      }),
      { name: "useSettingsStore" },
    ),
    {
      name: "settings",
      getStorage: () => getLocalStorage(),
    },
  ),
);
