import {
  Button,
  CloseIcon,
  Grid,
  InputGroup,
  Link,
  Modal,
  Typography,
} from "@flowriver/ui-kit";
import { useMutation } from "@tanstack/react-query";
import { Props as ModalProps } from "mui-modal-provider";
import { enqueueSnackbar } from "notistack";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { addCreditsFeedback } from "src/api/addCreditsFeedback";
import { useUserData } from "src/hooks/useUserData";

type Props = ModalProps;

type FormData = {
  email: string;
  text: string;
};

export const AddCreditsFeedbackModal: FC<Props> = ({
  open = true,
  onClose,
}) => {
  const { t } = useTranslation();

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<FormData>();

  const { email, isLoading: userDataLoading } = useUserData();

  const { mutate, isPending } = useMutation({
    mutationFn: (data: FormData) => addCreditsFeedback(data),
    onSuccess: () => {
      enqueueSnackbar(t("add_credits_feedback_modal.success_sending"), {
        variant: "success",
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar(t("errors.unknown"), {
        variant: "error",
      });
    },
  });

  const submitDisabled = userDataLoading || !email || !isValid || isPending;

  const onSubmit = ({ text }: FormData) => {
    if (submitDisabled) {
      return;
    }

    mutate({ email, text });
  };

  const feedbackEmail = t("add_credits_feedback_modal.email");

  const feedbackHref = `mailto:${feedbackEmail}`;

  return (
    <Modal
      open={!!open}
      variant="secondary"
      closeIcon={<CloseIcon onClick={onClose} />}
    >
      <Grid
        container
        flexDirection="column"
        maxWidth={500}
        width="100vw"
        pt={12}
        pb={9}
        px={12}
      >
        <Typography fontFamily="Space Grotesk" variant="h3" mb={2}>
          {t("add_credits_feedback_modal.title")}
        </Typography>

        <Typography
          mb={7}
          fontFamily="Space Grotesk"
          fontSize={14}
          fontWeight={600}
          color="text.secondary"
        >
          <Trans
            i18nKey="add_credits_feedback_modal.description"
            components={{
              link: (
                <Link href={feedbackHref}>
                  <Typography
                    component="span"
                    fontFamily="Space Grotesk"
                    fontSize={14}
                    fontWeight={600}
                  >
                    {feedbackEmail}
                  </Typography>
                </Link>
              ),
            }}
          />
        </Typography>

        <Grid
          component="form"
          container
          flexDirection="column"
          gap={10}
          flexGrow={1}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            control={control}
            name="text"
            defaultValue=""
            rules={{ required: true, maxLength: 500 }}
            render={({ field }) => (
              <InputGroup
                variant="filled"
                multiline
                placeholder={t("add_credits_feedback_modal.placeholder")}
                {...field}
              />
            )}
          />

          <Button
            variant="contained"
            color="secondary"
            size="medium"
            type="submit"
            sx={{ width: "100%", py: 3 }}
            disabled={submitDisabled}
          >
            {t("add_credits_feedback_modal.submit_button")}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
