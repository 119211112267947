import { Divider, Grid } from "@flowriver/ui-kit";
import {
  Box,
  CoinIcon,
  CopyIcon,
  InputButton,
  InputGroup,
} from "@flowriver/ui-kit";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PlatformSelect } from "src/components/ReportInput/components/PlatformsSelect";
import { ensureHttps } from "src/components/ReportInput/helpers/ensureHttps";
import { GOOGLE_PLAY_URL } from "src/constants/constants";
import { Platforms } from "src/constants/Platforms";
import { useAddMobileApp } from "src/hooks/useAddMobileApp";
import { useAddWebApp } from "src/hooks/useAddWebApp";
import { InferType, object, string } from "yup";

type Props = {
  placeholder?: string;
  size: "small" | "medium";
  color: "dark" | "secondary";
  withRedirect?: boolean;
  addToDashboard?: boolean;
  platform?: Platforms;
  showSelect?: boolean;
  onSuccess?: () => void;
  onPaymentRequired?: () => void;
  onErrorModal?: () => void;
};

const schema = object({
  url: string().trim().required().transform(ensureHttps),
  platform: string().oneOf(Object.values(Platforms)),
}).required();

type FormData = InferType<typeof schema>;

export const ReportInput: FC<Props> = ({
  placeholder,
  size,
  color,
  withRedirect = true,
  showSelect = false,
  onSuccess: onSuccessProp,
  onErrorModal,
  onPaymentRequired,
}) => {
  const { t } = useTranslation();
  const placeholderText = placeholder ?? t("shared.report_input.placeholder");

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    resetField,
    setError,
  } = useForm<FormData>({ resolver: yupResolver(schema), mode: "onChange" });

  const url = watch("url");
  const isWebUrl = url ? !url.includes(GOOGLE_PLAY_URL) : false;

  const onSuccess = () => {
    resetField("url");
    if (onSuccessProp) {
      onSuccessProp();
    }
  };
  const onError = (message: string) => setError("url", { message });

  const { mutate: createWebApp, isPending: webAppPending } = useAddWebApp({
    onSuccess,
    onErrorModal,
    onError,
    onPaymentRequired,
    withRedirect,
  });

  const { mutate: createMobileApp, isPending: mobileAppPending } =
    useAddMobileApp({
      onSuccess,
      onErrorModal,
      onError,
      onPaymentRequired,
      withRedirect,
    });

  const onSubmit = ({ url, platform }: FormData) => {
    if (isWebUrl) {
      createWebApp({
        url,
        device: platform,
      });
      return;
    }
    createMobileApp({
      url: ensureHttps(url),
    });
  };

  const handleBlur = () => {
    if (url === "") {
      clearErrors("url");
    }
  };

  const handlePlatformChange = (platform: Platforms) => {
    setValue("platform", platform);
  };

  const hasError = !url && !errors.url ? undefined : Boolean(errors.url);
  const shouldShowSelect = showSelect || isWebUrl;
  const isPending = webAppPending || mobileAppPending;

  return (
    <Box minWidth={320}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="url"
          defaultValue=""
          render={({ field }) => (
            <InputGroup
              disabled={isPending}
              placeholder={placeholderText}
              autoComplete="off"
              size={size}
              color={color}
              icon={<CopyIcon />}
              startAdornment={
                shouldShowSelect ? (
                  <Grid container alignItems="center">
                    <PlatformSelect onChange={handlePlatformChange} />
                    <Divider orientation="vertical" sx={{ height: "50%" }} />
                  </Grid>
                ) : null
              }
              error={hasError}
              helperText={errors.url?.message}
              button={
                <InputButton type="submit">
                  {hasError === false ? (
                    <Grid container gap={1} alignItems="center">
                      {t("shared.report_input.button_text_valid")}
                      <CoinIcon sx={{ fontSize: 14 }} />
                    </Grid>
                  ) : (
                    t("shared.report_input.button_text")
                  )}
                </InputButton>
              }
              {...field}
              onBlur={handleBlur}
            />
          )}
        />
      </form>
    </Box>
  );
};
