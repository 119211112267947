import { Steps } from "src/components/PaymentModal/constants/Steps";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  step?: Steps;
  clientSecret?: string;
  orderId?: number;
  productId?: number;
  appUrl?: string;
  appId?: string;
  startPayment: ({
    clientSecret,
    orderId,
  }: {
    clientSecret?: string;
    orderId?: number;
    productId?: number;
  }) => void;
  choseTariff: ({
    url,
    appId,
    onPurchase,
  }: {
    url?: string;
    appId?: string;
    onPurchase?: () => void;
  }) => void;
  handleSuccessPayment: () => void;
  handleCloseModal: () => void;
  closeModal: () => void;
  setCloseModal: (onClose: () => void) => void;
  setToDefault: () => void;
  onPurchase?: () => void;
};

const defaultState = {
  step: undefined,
  clientSecret: undefined,
  orderId: undefined,
  productId: undefined,
  appUrl: undefined,
  appId: undefined,
  closeModal: () => {},
  onPurchase: undefined,
};

export const usePaymentStore = create<State>()(
  devtools(
    (set, get) => ({
      ...defaultState,
      startPayment: ({ clientSecret, orderId, productId } = {}) =>
        set({ clientSecret, orderId, productId, step: Steps.Payment }),
      choseTariff: ({ url, appId, onPurchase }) =>
        set({ step: Steps.ChoseTariff, appUrl: url, appId, onPurchase }),
      handleCloseModal: () => {
        get().closeModal();
        get().setToDefault();
      },
      handleSuccessPayment: () =>
        set({ step: Steps.Success, appUrl: undefined, appId: undefined }),
      setCloseModal: (onClose) => set({ closeModal: onClose }),
      setToDefault: () => set(defaultState),
    }),
    { name: "usePaymentStore" },
  ),
);
