import { BaseButton } from "./BaseButton";
import { TypographyType } from "./constants";
import { css } from "./helpers/css";
import { styled } from "./helpers/styled";
import { useTypographyStyles } from "./helpers/useTypographyStyles";
export const Link = styled(BaseButton)(({ theme }) => css `
    ${useTypographyStyles(TypographyType.Body3)}

    color: ${theme.palette.primary.main};
    text-decoration: none;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    opacity: 1;

    transition: color 0.3s ease;

    :hover,
    :visited:hover {
      opacity: 0.6;
    }
  `);
