import { Menu as MenuBase, menuClasses } from "@mui/base";
import { css, styled } from "@mui/material";
export const Menu = styled(MenuBase)(({ theme, direction = "vertical" }) => css `
    width: 100%;
    & .${menuClasses.listbox} {
      width: 100%;
      display: flex;
      flex-direction: ${direction === "vertical" ? "column" : "row"};
      align-items: flex-start;
      padding: 0;
      margin: 0;
      gap: ${theme.spacing(1)};
    }
  `);
