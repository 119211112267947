import { Box } from "@flowriver/ui-kit";
import { FunctionComponent } from "react";

type Props = {
  active?: boolean;
};

export const OneTokenIcon: FunctionComponent<Props> = ({ active = false }) => {
  return (
    <Box sx={{ ["path, g"]: { transition: "fill 0.1s ease" } }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
      >
        <g clipPath="url(#a)">
          <rect
            width="22"
            height="22"
            fill={active ? "#0E0F0F" : "#2D74DF"}
            rx="11"
            transform="matrix(0 1 1 0 1 1)"
          />
          <path
            fill={active ? "#fff" : "#0E0F0F"}
            fillRule="evenodd"
            d="M14.662 11.29c1.232.136 1.57 1.771.492 2.385l-2.578 1.467a.87.87 0 0 0-.342 1.157l9.41 18.125c.042.082 1.96 3.6 2 3.517l4.16-31.75a.113.113 0 0 0-.002-.103l3.71-8.546-12.634 7.063-1.999 1.1-9.56 5.298a.154.154 0 0 0 .057.287h7.286ZM6.527 9.568l9.235-5.117c.985-.542 6.395-3.509 8.057-4.47l6.036-2.275-.616 7.595a1.75 1.75 0 0 1 .038 1.606l-4.583 34.27c-.63 1.295-3.842-4.721-4.505-5.998L10.78 17.053a2.509 2.509 0 0 1 .986-3.336l1.618-.921-6.188.121c-1.739-.192-2.2-2.505-.669-3.35Z"
            clipRule="evenodd"
          />
        </g>
        <rect
          width="23"
          height="23"
          x="-.5"
          y="-.5"
          stroke="#fff"
          rx="11.5"
          transform="matrix(0 1 1 0 1 1)"
        />
        <defs>
          <clipPath id="a">
            <rect
              width="22"
              height="22"
              fill="#fff"
              rx="11"
              transform="matrix(0 1 1 0 1 1)"
            />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};
