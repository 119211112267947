import {
  ArrowIcon,
  Dropdown,
  Grid,
  Menu,
  MenuButton,
  Typography,
} from "@flowriver/ui-kit";
import { FC, useState } from "react";
import { useUserData } from "src/hooks/useUserData";

type Props = {
  dropdownContent: React.ReactNode;
};

export const AccountDropdown: FC<Props> = ({ dropdownContent }) => {
  const { email, picture } = useUserData();

  const [isOpened, setIsOpened] = useState(false);

  const handleOpenChange = (_: unknown, open: boolean) => {
    setIsOpened(open);
  };

  const emailFirstLetter = email?.[0] || "";

  return (
    <Grid>
      <Dropdown onOpenChange={handleOpenChange}>
        <MenuButton
          sx={{
            border: 0,
            backgroundColor: "transparent",
            cursor: "pointer",
            ":hover": { backgroundColor: "action.hover" },
            borderRadius: 16,
            py: 1,
            px: 1.5,
          }}
        >
          <Grid container alignItems="center">
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              sx={{
                overflow: "hidden",
                width: 35,
                height: 35,
                borderRadius: "50%",
                backgroundColor: "common.blueDust",
                color: "primary.contrastText",
                textTransform: "uppercase",
              }}
            >
              {picture ? (
                <img
                  src={picture}
                  width={35}
                  height={35}
                  referrerPolicy="no-referrer"
                />
              ) : (
                <Typography variant="title3">{emailFirstLetter}</Typography>
              )}
            </Grid>

            <Grid pl={1}>
              <ArrowIcon
                sx={{
                  color: "text.secondary",
                  transform: isOpened ? "rotate(180deg)" : "rotate(0)",
                  width: 12,
                  height: 12,
                }}
              />
            </Grid>
          </Grid>
        </MenuButton>

        <Menu
          sx={{
            width: "auto",
            minWidth: "190px",
            maxWidth: "350px",
            borderRadius: 1,
            backgroundColor: "background.paper",
            px: 2,
            py: 3,
            boxShadow: 2,
            border: "1px solid",
            borderColor: "divider",
            zIndex: 100,
            overflow: "hidden",
            wordBreak: "break-all",
          }}
        >
          {dropdownContent}
        </Menu>
      </Dropdown>
    </Grid>
  );
};
