import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { paymentOrderCreate } from "src/api/paymentOrderCreate";
import { QueryKeys } from "src/constants/queryKeys";

type Options = {
  onSuccess?: ({
    clientSecret,
    orderId,
    productId,
  }: {
    clientSecret: string;
    orderId: number;
    productId: number;
  }) => void;
};

export const useCreatePaymentOrder = ({ onSuccess }: Options) => {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (productId: number) =>
      paymentOrderCreate({ product_id: productId }),
    mutationKey: [QueryKeys.PaymentOrderCreate],
    onSuccess: ({ data }, productId) => {
      const clientSecret = data.stripe_session?.client_secret;
      const id = data.id;

      if (clientSecret && id && productId) {
        if (onSuccess) {
          onSuccess({ clientSecret, orderId: id, productId });
        }
        return;
      }
      console.error("Missing client secret or order id");
      enqueueSnackbar(t("common.unknown"), { variant: "error" });
    },
  });
};
