import { FC, PropsWithChildren, StrictMode as ReactStrictMode } from "react";
import { IS_DEV } from "src/constants/environments";

export const StrictMode: FC<PropsWithChildren> = ({ children }) => {
  if (IS_DEV) {
    return <ReactStrictMode>{children}</ReactStrictMode>;
  }

  return <>{children}</>;
};
