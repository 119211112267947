import { useQuery } from "@tanstack/react-query";
import { currentUserGet } from "src/api/currentUserGet";
import { QueryKeys } from "src/constants/queryKeys";
import { StatusCodes } from "src/constants/statuses";

export const useGetCurrentUser = () =>
  useQuery({
    queryFn: () => currentUserGet(),
    queryKey: [QueryKeys.CurrentUser],
    select: (data) => data.data,
    throwOnError: (error) =>
      [
        StatusCodes.BAD_GATEWAY,
        StatusCodes.SERVICE_UNAVAILABLE,
        StatusCodes.GATEWAY_TIMEOUT,
      ].includes(error.response?.status ?? 0),
  });
