import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { buttonClasses } from "@mui/base";
import { Input as InputBase, inputClasses, } from "@mui/base/Input";
import { forwardRef } from "react";
import { Box } from "./Box";
import { TypographyType } from "./constants";
import { Grid } from "./Grid";
import { css } from "./helpers/css";
import { styled } from "./helpers/styled";
import { useTypographyStyles } from "./helpers/useTypographyStyles";
import { Typography } from "./Typography";
const InputGroupStyled = styled(InputBase)(({ theme, size = "medium", color = "dark", variant = "outlined", inheritFontFamily = false, error, icon, }) => css `
    &.${inputClasses.root} {
      display: flex;
      align-items: stretch;
      width: 100%;
      border-radius: ${theme.spacing(1)};
      outline: 1px solid ${theme.palette.dark.main};

      :hover {
        outline-width: 2px;
      }

      &.${inputClasses.focused} {
        outline-width: 2px;
      }

      &.${inputClasses.disabled} {
        outline-width: 1px;
      }
    }

    & .${buttonClasses.root} {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      min-width: 170px;
      padding: 0 ${theme.spacing(6)};
      border-top-right-radius: ${theme.spacing(1)};
      border-bottom-right-radius: ${theme.spacing(1)};
      border: 0;

      transition:
        background-color 0.3s ease,
        color 0.3s ease;

      cursor: pointer;
      z-index: 0;

      ${useTypographyStyles(TypographyType.Subtitle1)}

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: ${theme.palette.secondary.gradient};
        opacity: 0;
        transition: opacity 0.3s ease;
        z-index: -1;
        border-top-right-radius: ${theme.spacing(1)};
        border-bottom-right-radius: ${theme.spacing(1)};
      }

      &:hover {
        background-color: transparent;
        color: ${theme.palette.text.header};
      }

      &:hover::after {
        opacity: 1;
      }

      &:active::after {
        opacity: 0;
      }
    }

    ${inheritFontFamily &&
    css `
      input,
      textarea {
        font-family: inherit;
      }
    `}

    .${inputClasses.input} {
      color: ${theme.palette.text.header};
      border: 0;
      padding-left: ${theme.spacing(3)};
      padding-right: ${theme.spacing(3)};
      flex-grow: 1;
      height: 48px;
      background-color: transparent;
      outline: none;
      border-radius: ${theme.spacing(1)};

      ${useTypographyStyles(TypographyType.Body1)}

      &::placeholder {
        color: ${theme.palette.text.secondary};

        ${useTypographyStyles(TypographyType.Body1)}
      }

      ${icon &&
    `
        padding-left: ${theme.spacing(12)};

        & > svg {
          font-size: 18px;
        }
      `}
    }

    &.${inputClasses.error} {
      & svg {
        color: ${theme.palette.error.main};
      }
    }

    &.${inputClasses.disabled} {
      & .${inputClasses.input} {
        cursor: not-allowed;
      }

      & .${buttonClasses.root} {
        background-color: ${theme.palette.dark.disabled};
        color: ${theme.palette.common.gray};
        cursor: not-allowed;

        &:hover::after {
          opacity: 0;
        }
      }
    }

    ${size === "small" &&
    css `
      .${inputClasses.input} {
        height: 30px;

        ${useTypographyStyles(TypographyType.Body5)}

        &::placeholder {
          ${useTypographyStyles(TypographyType.Body5)}
        }
      }

      ${icon &&
        css `
        .${inputClasses.input} {
          padding-left: ${theme.spacing(7)};
        }
      `}

      & .${buttonClasses.root} {
        min-width: 88px;
        padding: 0 ${theme.spacing(1)};

        ${useTypographyStyles(TypographyType.Body5)}
      }
    `}

    ${color === "dark" &&
    css `
      .${inputClasses.input} {
        background-color: ${theme.palette.background.paper};
      }

      .${buttonClasses.root} {
        color: ${theme.palette.common.white};
        background-color: ${theme.palette.dark.main};
        outline-color: ${theme.palette.dark.main};

        &:active {
          background-color: ${theme.palette.common.blueNuclear};
          color: ${theme.palette.secondary.contrastText};
        }

        ${error === false &&
        css `
          background-color: ${theme.palette.common.blueNuclear};
        `}
      }

      &.${inputClasses.focused} {
        outline: 1px solid ${theme.palette.dark.main};
      }

      &:hover,
      &.${inputClasses.focused} {
        outline: 1px solid ${theme.palette.dark.main};
      }
    `}

    ${color === "secondary" &&
    css `
      .${inputClasses.input} {
        background-color: ${theme.palette.background.paper};
      }

      .${buttonClasses.root} {
        background-color: ${theme.palette.secondary.main};
        border-left: 1px solid ${theme.palette.common.darkGray};
        outline-color: ${theme.palette.common.darkGray};

        ${error === false &&
        css `
            background-color: ${theme.palette.secondary.dark};
            color: ${theme.palette.secondary.contrastText};
          `}

        &:active {
          background-color: ${theme.palette.secondary.dark};
          outline-color: ${theme.palette.secondary.dark};
          color: ${theme.palette.secondary.contrastText};
        }
      }

      &:hover {
        & .${inputClasses.root} {
          outline-color: ${theme.palette.common.blueDust};
        }
      }

      &.${inputClasses.focused} {
        & .${inputClasses.root} {
          outline-color: ${theme.palette.common.blue};
        }
      }

      &.${inputClasses.disabled} {
        .${buttonClasses.root}:active {
          background-color: ${theme.palette.common.darkGray};
        }
        &:hover .${inputClasses.root} {
          outline-color: ${theme.palette.common.darkGray};
        }
    `};

    ${variant === "solid" &&
    css `
      .${inputClasses.input} {
        border-color: transparent;
      }
    `}

    ${variant === "filled" &&
    css `
      &.${inputClasses.root} {
        outline-color: ${theme.palette.divider};
      }

      &.${inputClasses.focused}, &:hover {
        outline-color: ${theme.palette.dark.main};
      }

      .${inputClasses.input} {
        background-color: ${theme.palette.background.default};

        &::placeholder {
          color: ${theme.palette.common.darkGray};
        }
      }

      &.${inputClasses.error}.${inputClasses.root} {
        outline-color: ${theme.palette.error.main};
      }

      ${size === "medium" &&
        css `
        .${inputClasses.input} {
          height: 38px;
        }
      `}
    `}

    .${inputClasses.multiline} {
      padding-top: ${theme.spacing(3)};
      padding-bottom: ${theme.spacing(3)};
      resize: none;
      height: 150px;
    }
  `);
const SvgWrapper = styled(Box)(({ size }) => ({
    position: "absolute",
    left: size === "small" ? 8 : 16,
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
    color: "common.darkGray",
    display: "flex",
    ["& svg"]: {
        fontSize: size === "small" ? 12 : 18,
    },
}));
const InputComponent = forwardRef(({ ownerState: { icon, size }, ...props }, ref) => (_jsxs(Grid, { container: true, flexGrow: 1, position: "relative", children: [icon ? _jsx(SvgWrapper, { size: size, children: icon }) : null, _jsx("input", { ...props, ref: ref })] })));
const InputGroupComponent = forwardRef(({ button, icon, helperText, error, variant = "outlined", noPadding, ...props }, ref) => {
    const endAdornment = button;
    return (_jsxs(Grid, { container: true, flexDirection: "column", gap: 1, children: [_jsx(InputGroupStyled, { endAdornment: endAdornment, variant: variant, error: error, icon: icon, slots: { input: InputComponent }, ...props, ref: ref }), noPadding ? null : helperText ? (_jsx(Typography, { variant: "body5", color: error ? "error" : "text.header", children: Array.isArray(helperText) ? helperText.join(", ") : helperText })) : (_jsx(Box, { height: 12, width: "100%" }))] }));
});
export const InputGroup = styled(InputGroupComponent)({});
