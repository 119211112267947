import { paths } from "@flowriver/schema";
import { api } from "src/utils/api";

type Response =
  paths["/api/v1/payment_orders"]["post"]["responses"]["200"]["content"]["application/json"];

type Params =
  paths["/api/v1/payment_orders"]["post"]["requestBody"]["content"]["application/json"];

export const paymentOrderCreate = (params: Params) =>
  api.post<Response>("/v1/payment_orders", params);
