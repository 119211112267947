import {
  Button,
  CoinIcon,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@flowriver/ui-kit";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { AccountDropdown } from "src/components/AccountDropdown";
import { Routes } from "src/constants/routes";
import { useGetCurrentUser } from "src/hooks/useGetCurrentUser";
import { AccountDropdownContent } from "src/layouts/PrivateLayout/components/AccountDropdownContent";

export const TopBar: FC = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const { data: user } = useGetCurrentUser();

  const isPlansPage = location.pathname === Routes.Plans;

  return (
    <Grid
      container
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        py: 1,
        pr: 2,
        backgroundColor: "background.paper",
        minHeight: "71px",
      }}
    >
      <Grid container gap={6} sx={{ ml: "auto" }} alignItems="center">
        <Grid container gap={1} alignItems="center">
          {user ? (
            <>
              <CoinIcon sx={{ fontSize: 15 }} />
              <Typography variant="body1" color="text.secondary">
                {isMobile
                  ? user.credits
                  : t("top_bar.credits_left", { count: user.credits })}
              </Typography>
            </>
          ) : (
            <Skeleton variant="rounded" width={100} height={20} />
          )}
        </Grid>
        {isPlansPage ? null : (
          <Grid container alignItems="center">
            <Button
              as={Link}
              to={Routes.Plans}
              variant="contained"
              color="primary"
              size="medium"
              sx={{ width: 136 }}
            >
              {t("top_bar.update_button")}
            </Button>
          </Grid>
        )}

        <AccountDropdown dropdownContent={<AccountDropdownContent />} />
      </Grid>
    </Grid>
  );
};
