import { useQuery } from "@tanstack/react-query";
import { productsGet } from "src/api/productsGet";
import { QueryKeys } from "src/constants/queryKeys";

export const useGetProducts = () => {
  return useQuery({
    queryFn: () => productsGet(),
    queryKey: [QueryKeys.Products],
    select: (data) => data?.data,
  });
};
