import { createTheme } from "@mui/material";
import { darkPalette } from "./darkPalette";
import { lightPalette } from "./lightPalette";
import { typography } from "./typography";
const common = {
    typography,
    spacing: 4,
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1020,
            lg: 1280,
            xl: 1920,
        },
    },
};
export const lightTheme = createTheme({
    ...common,
    palette: lightPalette,
});
export const darkTheme = createTheme({
    ...common,
    palette: darkPalette,
});
export { ThemeMode } from "./ThemeMode";
