import { Box } from "@flowriver/ui-kit";
import { FunctionComponent } from "react";

type Props = {
  active?: boolean;
};

export const SeveralTokensIcon: FunctionComponent<Props> = ({
  active = false,
}) => {
  return (
    <Box sx={{ ["path, g"]: { transition: "fill 0.1s ease" } }}>
      <svg
        width="42"
        height="39"
        viewBox="0 0 42 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2121_3292)">
          <g clipPath="url(#clip1_2121_3292)">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(4.37114e-08 1 1 -4.37114e-08 1 16)"
              fill={active ? "#0E0F0F" : "#2D74DF"}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.6616 26.2902C15.8944 26.4265 16.232 28.0612 15.154 28.6747L12.5761 30.1418C12.1697 30.3731 12.0187 30.8842 12.2342 31.2992L21.6432 49.4237C21.6858 49.5058 23.604 53.0241 23.6444 52.941L27.8041 21.1909C27.82 21.1581 27.8191 21.1196 27.8016 21.0877L31.5116 12.5422L18.8779 19.6048C18.96 19.5595 16.9339 20.676 16.8794 20.706L7.31852 26.0029C7.18725 26.0753 7.22681 26.2736 7.37581 26.2901L14.6616 26.2902ZM6.52736 24.5683L15.7617 19.4514C16.7467 18.9086 22.1575 15.9426 23.8194 14.9805L29.8545 12.7064L29.2387 20.301C29.511 20.7985 29.5255 21.3972 29.2774 21.9073L24.694 56.1773C24.0645 57.4717 20.8524 51.4561 20.1892 50.1786L10.7801 32.0541C10.1591 30.8578 10.5944 29.3846 11.7658 28.718L13.3838 27.7971L7.19573 27.9185C5.45749 27.7262 4.99596 25.4129 6.52736 24.5683Z"
              fill={active ? "#fff" : "#0E0F0F"}
            />
          </g>
          <rect
            x="-0.5"
            y="-0.5"
            width="23"
            height="23"
            rx="11.5"
            transform="matrix(4.37114e-08 1 1 -4.37114e-08 1 16)"
            stroke="white"
          />
        </g>
        <g clipPath="url(#clip2_2121_3292)">
          <g clipPath="url(#clip3_2121_3292)">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(4.37114e-08 1 1 -4.37114e-08 10 1)"
              fill={active ? "#0E0F0F" : "#2D74DF"}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.6616 11.2902C24.8944 11.4265 25.232 13.0612 24.154 13.6747L21.5761 15.1418C21.1697 15.3731 21.0187 15.8842 21.2342 16.2992L30.6432 34.4237C30.6858 34.5058 32.604 38.0241 32.6444 37.941L36.8041 6.19085C36.82 6.15808 36.8191 6.11962 36.8016 6.08765L40.5116 -2.45782L27.8779 4.60475C27.96 4.55951 25.9339 5.67597 25.8794 5.70604L16.3185 11.0029C16.1873 11.0753 16.2268 11.2736 16.3758 11.2901L23.6616 11.2902ZM15.5274 9.56833L24.7617 4.45137C25.7467 3.90863 31.1575 0.942597 32.8194 -0.0195234L38.8545 -2.29363L38.2387 5.30095C38.511 5.79852 38.5255 6.3972 38.2774 6.90731L33.694 41.1773C33.0645 42.4717 29.8524 36.4561 29.1892 35.1786L19.7801 17.0541C19.1591 15.8578 19.5944 14.3846 20.7658 13.718L22.3838 12.7971L16.1957 12.9185C14.4575 12.7262 13.996 10.4129 15.5274 9.56833Z"
              fill={active ? "#fff" : "#0E0F0F"}
            />
          </g>
          <rect
            x="-0.5"
            y="-0.5"
            width="23"
            height="23"
            rx="11.5"
            transform="matrix(4.37114e-08 1 1 -4.37114e-08 10 1)"
            stroke="white"
          />
        </g>
        <g clipPath="url(#clip4_2121_3292)">
          <g clipPath="url(#clip5_2121_3292)">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(4.37114e-08 1 1 -4.37114e-08 19 16)"
              fill={active ? "#0E0F0F" : "#2D74DF"}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M32.6616 26.2902C33.8944 26.4265 34.232 28.0612 33.154 28.6747L30.5761 30.1418C30.1697 30.3731 30.0187 30.8842 30.2342 31.2992L39.6432 49.4237C39.6858 49.5058 41.604 53.0241 41.6444 52.941L45.8041 21.1909C45.82 21.1581 45.8191 21.1196 45.8016 21.0877L49.5116 12.5422L36.8779 19.6048C36.96 19.5595 34.9339 20.676 34.8794 20.706L25.3185 26.0029C25.1873 26.0753 25.2268 26.2736 25.3758 26.2901L32.6616 26.2902ZM24.5274 24.5683L33.7617 19.4514C34.7467 18.9086 40.1575 15.9426 41.8194 14.9805L47.8545 12.7064L47.2387 20.301C47.511 20.7985 47.5255 21.3972 47.2774 21.9073L42.694 56.1773C42.0645 57.4717 38.8524 51.4561 38.1892 50.1786L28.7801 32.0541C28.1591 30.8578 28.5944 29.3846 29.7658 28.718L31.3838 27.7971L25.1957 27.9185C23.4575 27.7262 22.996 25.4129 24.5274 24.5683Z"
              fill={active ? "#fff" : "#0E0F0F"}
            />
          </g>
          <rect
            x="-0.5"
            y="-0.5"
            width="23"
            height="23"
            rx="11.5"
            transform="matrix(4.37114e-08 1 1 -4.37114e-08 19 16)"
            stroke="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_2121_3292">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0 15)"
            />
          </clipPath>
          <clipPath id="clip1_2121_3292">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(4.37114e-08 1 1 -4.37114e-08 1 16)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip2_2121_3292">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(9)"
            />
          </clipPath>
          <clipPath id="clip3_2121_3292">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(4.37114e-08 1 1 -4.37114e-08 10 1)"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip4_2121_3292">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(18 15)"
            />
          </clipPath>
          <clipPath id="clip5_2121_3292">
            <rect
              width="22"
              height="22"
              rx="11"
              transform="matrix(4.37114e-08 1 1 -4.37114e-08 19 16)"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};
