import { QueryClient } from "@tanstack/react-query";
import { StatusCodes } from "src/constants/statuses";

const DELAY_INTERVAL = 2000;
const MAX_DELAY = 30000;
const MAX_ATTEMPTS = 4;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryDelay: (count) => Math.min(DELAY_INTERVAL ** count, MAX_DELAY),
      retry: (count, error) => {
        if (
          error?.response?.status === StatusCodes.UNAUTHORIZED &&
          count < MAX_ATTEMPTS
        ) {
          return true;
        }
        return false;
      },
    },
  },
});
