type Options = {
  price: number;
  currency: string;
};

export const formatPrice = ({ price, currency }: Options) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }).format(price);
};
