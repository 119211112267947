import { useModal } from "mui-modal-provider";
import { AddCreditsFeedbackModal } from "src/components/AddCreditsFeedbackModal/AddCreditsFeedbackModal";

type Options = {
  onClose?: () => void;
};

export const useFeedbackModal = ({ onClose }: Options = {}) => {
  const { showModal } = useModal();

  const showFeedbackModal = () => {
    showModal(AddCreditsFeedbackModal, {
      open: true,
      onClose,
    });
  };

  return { showFeedbackModal };
};
