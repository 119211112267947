import { Box, Grid, Link, Typography } from "@flowriver/ui-kit";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import backArrow from "src/assets/back-arrow.svg";
import backgroundGrid from "src/assets/background-grid.svg";
import backImg from "src/pages/Error/assets/back-img.webp";
import serverError from "src/pages/Error/assets/server-error.svg";

export const Error: FunctionComponent = () => {
  const { t } = useTranslation();

  const handleRefreshClick = () => {
    window.location.reload();
  };

  return (
    <Grid
      container
      sx={{
        backgroundColor: "common.white",
        backgroundImage: `url(${backgroundGrid})`,
        backgroundSize: "cover",
        height: "100vh",
        alignItems: "center",
        flexGrow: 1,
        justifyContent: "center",
        p: 5,
      }}
    >
      <Grid
        container
        gap={{ xs: 5, lg: 10 }}
        flexDirection={{ xs: "column", lg: "row" }}
      >
        <Grid
          container
          flexDirection="column"
          alignItems={{ xs: "center", lg: "flex-start" }}
          gap={4}
          order={{ xs: 2, lg: 0 }}
        >
          <Grid container>
            <Box
              component="img"
              src={serverError}
              width="100%"
              maxWidth={{ xs: 300, lg: 500, xl: 931 }}
              height="auto"
              alt="error"
            />
          </Grid>
          <Grid maxWidth={625} mb={15}>
            <Typography
              sx={{
                fontFamily: "Space Grotesk",
                fontSize: "26px",
                lineHeight: "39px",
                color: "text.secondary",
                textAlign: { xs: "center", lg: "left" },
              }}
            >
              {t("error.description")}
            </Typography>
          </Grid>
          <Grid container alignItems="center" gap={3}>
            <Box
              component={Link}
              onClick={handleRefreshClick}
              sx={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box component="span" sx={{ mr: 3 }}>
                <img src={backArrow} width={49} height={33} alt="back" />
              </Box>
              <Typography
                component="span"
                sx={{
                  fontSize: "26px",
                  lineHeight: 1.5,
                  color: "common.blueNuclear",
                  fontFamily: "Space Grotesk",
                }}
              >
                {t("error.reload_button")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container order={{ xs: 1, lg: 0 }} justifyContent="center">
          <Box
            component="img"
            src={backImg}
            width={{ xs: 150, lg: 412 }}
            height="auto"
            alt="flow img"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
