import { useAnalytics } from "@flowriver/analytics";
import { Box, Button, CheckIcon, Grid, Typography } from "@flowriver/ui-kit";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import rocketIconUrl from "src/assets/rocket.svg";
import { usePaymentStore } from "src/components/PaymentModal/store/usePaymentStore";
import { AmplitudeEventName } from "src/constants/analytics";
import { useGetProducts } from "src/hooks/useGetProducts";
import { SeveralTokensIcon } from "src/icons/SeveralTokensIcon";

export const Success: FC = () => {
  const { t } = useTranslation();

  const { amplitude } = useAnalytics();

  useEffect(() => {
    amplitude.logEvent(AmplitudeEventName.CheckoutSuccessViewed);
  }, []);

  const { productId, handleCloseModal } = usePaymentStore();
  const { data } = useGetProducts();

  const handleButtonClick = () => {
    amplitude.logEvent(AmplitudeEventName.CheckoutSuccessCloseTapped);

    handleCloseModal();
  };

  const product = data?.items?.find((product) => product.id === productId);

  return (
    <Grid
      container
      flexDirection="column"
      maxWidth={480}
      width="100%"
      px={12}
      pt={16}
      pb={11}
      position="relative"
    >
      <Box
        component="img"
        src={rocketIconUrl}
        width={113}
        height={102}
        top={36}
        right={36}
        position="absolute"
      />
      <Typography
        fontSize={30}
        lineHeight={1.32}
        fontWeight={700}
        fontFamily="Space Grotesk"
        mb={6}
      >
        {t("payment_modal.success.title")}
      </Typography>
      <Typography
        fontSize={14}
        fontWeight={600}
        fontFamily="Space Grotesk"
        color="text.secondary"
        mb={5}
      >
        {t("payment_modal.success.description")}
      </Typography>

      {product ? (
        <Grid
          container
          alignItems="center"
          mt={5}
          p={4}
          mb={6}
          sx={{ border: "1px solid", borderColor: "common.gray" }}
        >
          <Grid mr={2}>
            <SeveralTokensIcon />
          </Grid>
          <Grid container flexDirection="column">
            <Typography variant="subtitle1">{product.name}</Typography>
            <Typography variant="body2" color="text.secondary">
              {t("payment_modal.success.added_description")}
            </Typography>
          </Grid>
          <Grid container ml="auto">
            <CheckIcon sx={{ fontSize: 34 }} />
          </Grid>
        </Grid>
      ) : null}

      <Grid container justifyContent="center">
        <Button
          variant="contained"
          size="medium"
          color="primary"
          sx={{ py: 2, width: "141px" }}
          onClick={handleButtonClick}
        >
          <Typography variant="subtitle3">
            {t("payment_modal.success.button_text")}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};
