import Cookies from "js-cookie";
import { useEffect } from "react";
import { GOOGLE_PLAY_URL } from "src/constants/constants";
import { CookieNames } from "src/constants/CookieNames";
import { COOKIE_DOMAIN } from "src/constants/environments";
import { useAddMobileApp } from "src/hooks/useAddMobileApp";
import { useAddWebApp } from "src/hooks/useAddWebApp";
import { useGetCurrentUser } from "src/hooks/useGetCurrentUser";

export const useCheckRequestedAppApp = () => {
  const appUrl = Cookies.get(CookieNames.RequestedAppUrl);

  const { mutate: addWebApp } = useAddWebApp({ withRedirect: true });
  const { mutate: addMobileApp } = useAddMobileApp({ withRedirect: true });

  const { data: currentUser } = useGetCurrentUser();

  useEffect(() => {
    if (!appUrl || !currentUser) {
      return;
    }

    const isMobileApp = appUrl.includes(GOOGLE_PLAY_URL);

    Cookies.remove(CookieNames.RequestedAppUrl, { domain: COOKIE_DOMAIN });

    if (isMobileApp) {
      addMobileApp({ url: appUrl });
      return;
    }
    addWebApp({ url: appUrl });
  }, [appUrl, currentUser]);
};
