import { components, paths } from "@flowriver/schema";
import { api } from "src/utils/api";

type Params =
  paths["/api/v1/mobile_apps"]["post"]["requestBody"]["content"]["application/json"];

type Response =
  components["responses"]["CreateMobileApp200"]["content"]["application/json"];

export const mobileAppPost = ({ url }: Params) =>
  api.post<Response>("/v1/mobile_apps", { url });
