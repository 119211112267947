import { Container, Grid, useMediaQuery, useTheme } from "@flowriver/ui-kit";
import { FC, memo } from "react";
import { Outlet } from "react-router-dom";
import { useBookCallModal } from "src/components/BookCallModal/useBookCallModal";
import { useCheckRequestedAppApp } from "src/hooks/useCheckRequestedAppApp";
import { useHandlePostLoginRedirect } from "src/hooks/useHandlePostLoginRedirect";
import { SideMenu } from "src/layouts/PrivateLayout/components/SideMenu";
import { TopBar } from "src/layouts/PrivateLayout/components/TopBar";
import {
  SIDE_MENU_COLLAPSED_WIDTH,
  SIDE_MENU_WIDTH,
} from "src/layouts/PrivateLayout/constants";

export const Content: FC = memo(() => {
  useHandlePostLoginRedirect();
  useCheckRequestedAppApp();
  useBookCallModal();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container>
      <SideMenu />

      <Grid
        container
        flexDirection="column"
        sx={{
          minHeight: "100vh",
          flexGrow: 1,
          overflow: "auto",
          marginLeft: `${isMobile ? SIDE_MENU_COLLAPSED_WIDTH : SIDE_MENU_WIDTH}px`,
        }}
      >
        <TopBar />
        <Grid container flexGrow={1}>
          <Grid flexGrow={1} sx={{ backgroundColor: "background.default" }}>
            <Container maxWidth="lg" sx={{ py: 7 }}>
              <Outlet />
            </Container>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
