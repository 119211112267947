import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { sessionGet } from "src/api/sessionGet";
import { LocalStorageKeys } from "src/constants/localStorageKeys";
import { QueryKeys } from "src/constants/queryKeys";
import { Routes } from "src/constants/routes";
import { StatusCodes } from "src/constants/statuses";

const REFETCH_INTERVAL = 1000 * 60 * 2;
const RETRY_DELAY = 5000;

export const useCheckAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: session, error } = useQuery({
    queryFn: () => sessionGet(),
    queryKey: [QueryKeys.WhoAmI],
    refetchInterval: REFETCH_INTERVAL,
    retryDelay: RETRY_DELAY,
    refetchIntervalInBackground: true,
    refetchOnReconnect: true,
    retry: (_, error) => error?.response?.status !== StatusCodes.UNAUTHORIZED,
  });

  useEffect(() => {
    if (!session) {
      return;
    }

    const unverifiedAddress = session.identity?.verifiable_addresses?.find(
      (address) => !address.verified,
    );

    if (unverifiedAddress) {
      return navigate({
        pathname: Routes.Verification,
        search: createSearchParams({
          email: unverifiedAddress.value,
        }).toString(),
      });
    }

    setIsAuthenticated(true);
  }, [session]);

  useEffect(() => {
    if (!error) {
      return;
    }
    if (error?.response?.status === StatusCodes.UNAUTHORIZED) {
      const postLoginRedirectURL =
        window.location.pathname + window.location.search;

      const savedUrl = localStorage.getItem(
        LocalStorageKeys.PostLoginRedirectURL,
      );

      const shouldStoreUrl = !savedUrl && postLoginRedirectURL !== Routes.Login;

      if (shouldStoreUrl) {
        localStorage.setItem(
          LocalStorageKeys.PostLoginRedirectURL,
          postLoginRedirectURL,
        );
      }

      navigate(Routes.Login);
      return;
    }

    navigate(Routes.Error);
  }, [error]);

  useEffect(
    () => () => {
      queryClient.getQueryCache().clear();
    },
    [],
  );

  return isAuthenticated;
};
