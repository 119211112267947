export enum Routes {
  Web2App = "/",
  Web2AppPage = "/web2app/:appId",
  Report = "/report",
  Version = "/versions/:versionId",
  Plans = "/plans",
  MobileApps = "/apps",
  MobileApp = "/apps/:appId",
  Updates = "/versions",
  Ios = "/ios-apps",
  Settings = "/settings",
  Login = "/auth/login",
  Register = "/auth/register",
  Recovery = "/auth/recovery",
  ChangePassword = "/auth/change-password",
  Verification = "/auth/verification",
  Error = "/error",
}
