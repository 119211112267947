import { Box } from "@flowriver/ui-kit";
import { FunctionComponent } from "react";

type Props = {
  active?: boolean;
};

export const TwoTokensIcon: FunctionComponent<Props> = ({ active = false }) => {
  return (
    <Box sx={{ ["path, g"]: { transition: "fill 0.1s ease" } }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="24"
        fill="none"
      >
        <g clipPath="url(#a)">
          <g clipPath="url(#b)">
            <rect
              width="22"
              height="22"
              fill={active ? "#0E0F0F" : "#2D74DF"}
              rx="11"
              transform="matrix(0 1 1 0 1 1)"
            />
            <path
              fill={active ? "#fff" : "#0E0F0F"}
              fillRule="evenodd"
              d="M14.662 11.29c1.232.137 1.57 1.771.492 2.385l-2.578 1.467a.87.87 0 0 0-.342 1.157l9.41 18.125c.042.082 1.96 3.6 2 3.517l4.16-31.75a.113.113 0 0 0-.002-.103l3.71-8.546-12.634 7.063-1.999 1.101-9.56 5.297a.154.154 0 0 0 .057.287h7.286ZM6.527 9.568l9.235-5.117c.985-.542 6.395-3.508 8.057-4.47l6.036-2.275-.616 7.595a1.75 1.75 0 0 1 .038 1.606l-4.583 34.27c-.63 1.295-3.842-4.72-4.505-5.998L10.78 17.054a2.509 2.509 0 0 1 .986-3.336l1.618-.92-6.188.12c-1.739-.192-2.2-2.505-.669-3.35Z"
              clipRule="evenodd"
            />
          </g>
          <rect
            width="23"
            height="23"
            x="-.5"
            y="-.5"
            stroke="#fff"
            rx="11.5"
            transform="matrix(0 1 1 0 1 1)"
          />
        </g>
        <g clipPath="url(#c)">
          <g clipPath="url(#d)">
            <rect
              width="22"
              height="22"
              fill={active ? "#0E0F0F" : "#2D74DF"}
              rx="11"
              transform="matrix(0 1 1 0 19 1)"
            />
            <path
              fill={active ? "#fff" : "#0E0F0F"}
              fillRule="evenodd"
              d="M32.662 11.29c1.232.137 1.57 1.771.492 2.385l-2.578 1.467a.87.87 0 0 0-.342 1.157l9.41 18.125c.042.082 1.96 3.6 2 3.517l4.16-31.75a.113.113 0 0 0-.002-.103l3.71-8.546-12.634 7.063-1.999 1.101-9.56 5.297a.154.154 0 0 0 .057.287h7.286Zm-8.135-1.722 9.235-5.117c.985-.542 6.395-3.508 8.057-4.47l6.035-2.275-.615 7.595a1.75 1.75 0 0 1 .038 1.606l-4.583 34.27c-.63 1.295-3.842-4.72-4.505-5.998L28.78 17.054a2.509 2.509 0 0 1 .986-3.336l1.618-.92-6.188.12c-1.739-.192-2.2-2.505-.669-3.35Z"
              clipRule="evenodd"
            />
          </g>
          <rect
            width="23"
            height="23"
            x="-.5"
            y="-.5"
            stroke="#fff"
            rx="11.5"
            transform="matrix(0 1 1 0 19 1)"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h24v24H0z" />
          </clipPath>
          <clipPath id="b">
            <rect
              width="22"
              height="22"
              fill="#fff"
              rx="11"
              transform="matrix(0 1 1 0 1 1)"
            />
          </clipPath>
          <clipPath id="c">
            <path fill="#fff" d="M18 0h24v24H18z" />
          </clipPath>
          <clipPath id="d">
            <rect
              width="22"
              height="22"
              fill="#fff"
              rx="11"
              transform="matrix(0 1 1 0 19 1)"
            />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};
