import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { APP_LINK_SEARCH_PARAM } from "src/constants/constants";

export const useSaveAppUrl = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const url = searchParams.get(APP_LINK_SEARCH_PARAM);

  useEffect(() => {
    if (!url) {
      return;
    }

    localStorage.setItem(APP_LINK_SEARCH_PARAM, url);
    searchParams.delete(APP_LINK_SEARCH_PARAM);
    setSearchParams(searchParams);
  }, [url, searchParams]);
};
