import {
  DeskType,
  VersionFilters,
  ViewMode,
} from "src/components/Desk/constants";
import { selectIsReady } from "src/components/Desk/store/selectors";
import { FlowEdge, FlowNode } from "src/components/Desk/types";
import { AppStatuses } from "src/constants/AppStatuses";
import { AppVersion } from "src/types/AppVersion";
import { Dashboard } from "src/types/Dashboard";
import { WebFunnelScreen } from "src/types/types";
import { UserWebAppFlow } from "src/types/UserWebAppFlow";
import { WebAppVersion } from "src/types/WebAppVersion";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export type State = {
  viewMode: ViewMode;
  type?: DeskType;
  appId?: string;
  dashboard?: Dashboard;
  versions: Record<string, AppVersion>;
  webAppVersions: Record<string, WebAppVersion>;
  versionId?: string;
  webFunnelScreens: Record<
    string,
    Array<WebFunnelScreen & { flowId?: string }>
  >;
  flows: Record<string, UserWebAppFlow & { versionId: string }>;
  nodes: FlowNode[];
  edges: FlowEdge[];
  onboardingCompleted?: boolean;
  canExplore?: boolean;
  reportReady?: boolean;
  status?: AppStatuses;
  isRemap: boolean;
  isOverload: boolean;
  isOpened: boolean;
  versionFilter: VersionFilters;
  setStatus: (status: AppStatuses) => void;
  setReportReady: (reportReady: boolean) => void;
  openDesk: () => void;
  closeDesk: () => void;
  setCanExplore: (couldExplore: boolean) => void;
  setOnboardingCompleted: (onboardingCompleted: boolean) => void;
  updateViewMode: () => void;
  setAppData: ({
    appId,
    versionId,
    type,
  }: {
    appId?: string;
    versionId?: string;
    type: DeskType;
  }) => void;
  setWebAppVersion: ({ version }: { version: WebAppVersion }) => void;
  setWebAppVersions: ({ versions }: { versions: WebAppVersion[] }) => void;
  setVersionFilter: (filter: VersionFilters) => void;
  setVersionFlows: ({
    flows,
    versionId,
  }: {
    flows: UserWebAppFlow[];
    versionId: string;
  }) => void;
  setFlow: ({
    flow,
    versionId,
  }: {
    flow: UserWebAppFlow;
    versionId: string;
  }) => void;
  setDashboard: ({ dashboard }: { dashboard: Dashboard }) => void;
  setWebFunnelScreens: ({
    versionId,
    flowId,
    screens,
  }: {
    versionId: string;
    flowId: string;
    screens: Array<WebFunnelScreen & { flowId?: string }>;
  }) => void;
  setNodes: (nodes: FlowNode[]) => void;
  setEdges: (edges: FlowEdge[]) => void;
  setRemap: (isRemap: boolean) => void;
  setOverload: (isOverload: boolean) => void;
  setToDefault: () => void;
};

const defaultState = {
  viewMode: ViewMode.Loading,
  type: undefined,
  appId: undefined,
  dashboardId: undefined,
  dashboard: undefined,
  versions: {},
  webAppVersions: {},
  versionFilter: VersionFilters.Three,
  screens: {},
  webFunnelScreens: {},
  flows: {},
  versionId: undefined,
  status: undefined,
  onboardingCompleted: undefined,
  canExplore: undefined,
  reportReady: undefined,
  apps: {},
  nodes: [],
  edges: [],
  isRemap: false,
  isOverload: false,
  isScreensLoading: true,
  isOpened: false,
};

export const useDeskStore = create<State>()(
  devtools(
    (set, get) => ({
      ...defaultState,
      setReportReady: (reportReady) => set({ reportReady }),
      setStatus: (status) => set({ status }),
      setAppData: ({ versionId, appId, type }) =>
        set({ versionId, appId, type }),
      setDashboard: ({ dashboard }) =>
        set({ dashboard, type: DeskType.Dashboard }),
      setWebFunnelScreens: ({ versionId, flowId, screens: getScreens }) =>
        set(({ webFunnelScreens }) => ({
          webFunnelScreens: {
            ...webFunnelScreens,
            [flowId ?? versionId]: getScreens,
          },
        })),
      setVersionFilter: (filter) => set({ versionFilter: filter }),
      setCanExplore: (couldExplore) => set({ canExplore: couldExplore }),
      setOnboardingCompleted: (onboardingCompleted) =>
        set({ onboardingCompleted }),
      setWebAppVersion: ({ version }) => {
        set(({ webAppVersions }) => {
          return version.id
            ? { webAppVersions: { ...webAppVersions, [version.id]: version } }
            : {};
        });
      },
      setWebAppVersions: ({ versions }) => {
        if (!versions.length) {
          return;
        }
        set(() => ({
          webAppVersions: Object.values(versions).reduce(
            (acc: Record<string, WebAppVersion>, version) => ({
              ...acc,
              [version.id || ""]: version,
            }),
            {},
          ),
        }));
      },
      setVersionFlows: ({ flows: newFlows, versionId }) => {
        set(({ flows }) => {
          const flowsCopy = { ...flows };

          Object.entries(flowsCopy).forEach(([key, value]) => {
            if (value.versionId === versionId) {
              delete flowsCopy[key];
            }
          });

          newFlows.forEach((flow) => {
            if (flow.id) {
              flowsCopy[flow.id] = { ...flow, versionId };
            }
          });

          return { flows: { ...flowsCopy } };
        });
      },
      setFlow: ({ flow, versionId }) => {
        set(({ flows }) => {
          return flow.id
            ? { flows: { ...flows, [flow.id]: { ...flow, versionId } } }
            : {};
        });
      },
      setNodes: (nodes) => set({ nodes }),
      setEdges: (edges) => set({ edges }),
      setRemap: (isRemap) => set({ isRemap }),
      setOverload: (isOverload) => set({ isOverload }),
      setToDefault: () => set(defaultState),
      openDesk: () => set({ viewMode: ViewMode.Desk, isOpened: true }),
      closeDesk: () => {
        set({ isOpened: false });
        const isReady = selectIsReady(get());

        if (!isReady) {
          set({ viewMode: ViewMode.Progress });
          return;
        }
        set({ viewMode: ViewMode.Preview });
      },
      updateViewMode: () => {
        const viewMode = get().viewMode;
        const status = get().status;
        const reportReady = get().reportReady;
        const isRemap = get().isRemap;
        const isOverload = get().isOverload;
        const isReady = selectIsReady(get());

        if (status === AppStatuses.Failed) {
          set({ viewMode: ViewMode.Error });

          return;
        }

        if (isRemap) {
          set({ viewMode: ViewMode.Remap });

          return;
        }

        if (isOverload) {
          set({ viewMode: ViewMode.ServiceOverloaded });
          return;
        }

        if (!isReady && viewMode !== ViewMode.Desk) {
          set({ viewMode: ViewMode.Progress });
          return;
        }

        if (status === AppStatuses.Doubtful && viewMode !== ViewMode.Desk) {
          set({ viewMode: ViewMode.Preview });
          return;
        }

        if (status === AppStatuses.Processed && viewMode !== ViewMode.Desk) {
          set({ viewMode: ViewMode.Preview });
          return;
        }

        if (reportReady === true && viewMode !== ViewMode.Desk) {
          set({ viewMode: ViewMode.Preview });
          return;
        }
      },
    }),
    { name: "useDeskStore" },
  ),
);
