import { Grid, Typography } from "@flowriver/ui-kit";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  active?: boolean;
};

export const BetaBadge: FC<Props> = ({ active = true }) => {
  const { t } = useTranslation();

  const backgroundColor = active ? "common.blue" : "common.darkGray2";
  const color = active ? "common.white" : "common.softBlack";

  return (
    <Grid
      container
      px={1}
      sx={{
        backgroundColor,
        borderRadius: 1,
        transition: "background-color 0.3s ease",
      }}
    >
      <Typography
        component="span"
        variant="body1"
        fontFamily="Space Grotesk, sans-serif;"
        fontSize={12}
        lineHeight="16px"
        color={color}
        sx={{ transition: "color 0.3s ease;" }}
      >
        {t("shared.beta_badge")}
      </Typography>
    </Grid>
  );
};
