import { useModal } from "mui-modal-provider";
import { BlockedErrorModal } from "src/components/AppErrorModals/components/BlockedErrorModal";
import { IosErrorModal } from "src/components/AppErrorModals/components/IosErrorModal";
import { PaidErrorModal } from "src/components/AppErrorModals/components/PaidErrorModal";
import { UnavailableModal } from "src/components/AppErrorModals/components/UnavailableModal";
import { UnsupportedModal } from "src/components/AppErrorModals/components/UnsupportedModal";

export const useAppErrorModals = () => {
  const { showModal } = useModal();

  const showPaidError = () => {
    showModal(PaidErrorModal, { open: true });
  };

  const showIosError = () => {
    showModal(IosErrorModal, { open: true });
  };

  const showBlockedError = () => {
    showModal(BlockedErrorModal, { open: true });
  };

  const showUnsupportedError = () => {
    showModal(UnsupportedModal, { open: true });
  };

  const showUnavailableError = () => {
    showModal(UnavailableModal, { open: true });
  };

  return {
    showPaidError,
    showIosError,
    showBlockedError,
    showUnavailableError,
    showUnsupportedError,
  };
};
