import { useAnalytics } from "@flowriver/analytics";
import {
  Grid,
  MenuItem,
  Switch,
  ThemeMode,
  Typography,
} from "@flowriver/ui-kit";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserInfo } from "src/components/UserInfo";
import { ory } from "src/configs/ory";
import { AmplitudeEventName } from "src/constants/analytics";
import { Routes } from "src/constants/routes";
import { useSettingsStore } from "src/hooks/useSettingsStore";

export const AccountDropdownContent: FC = () => {
  const { t } = useTranslation();

  const { amplitude } = useAnalytics();

  const navigate = useNavigate();

  const { theme, setTheme } = useSettingsStore();

  const handleLogout = async () => {
    amplitude.logEvent(AmplitudeEventName.PAProfileLogoutTapped);

    try {
      const { data: flow } = await ory.createBrowserLogoutFlow();
      await ory.updateLogoutFlow({ token: flow.logout_token });
      navigate(Routes.Login);
    } catch (error) {
      console.error(error);
    }
  };

  const handleThemeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTheme(event.target.checked ? ThemeMode.dark : ThemeMode.light);
  };

  return (
    <>
      <Grid px={4} pb={2}>
        <UserInfo />
      </Grid>

      <Grid
        px={4}
        pb={2}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
        }}
        gap={1}
        component="label"
      >
        <Switch checked={theme === "dark"} onChange={handleThemeChange} />
        <Typography variant="body1">{t("user_menu.dark_mode")}</Typography>
      </Grid>

      <MenuItem onClick={handleLogout}>{t("user_menu.logout")}</MenuItem>
    </>
  );
};
