import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LocalStorageKeys } from "src/constants/localStorageKeys";

export const useHandlePostLoginRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const postLoginRedirectUrl = localStorage.getItem(
      LocalStorageKeys.PostLoginRedirectURL,
    );

    if (postLoginRedirectUrl) {
      localStorage.removeItem(LocalStorageKeys.PostLoginRedirectURL);
      navigate(postLoginRedirectUrl);
    }
  }, [navigate]);
};
