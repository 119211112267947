import { FC, PropsWithChildren } from "react";
import { ManyTokenWrapper } from "src/components/ChoosePlan/components/wrappers/ManyTokenWrapper";
import { OneTokenWrapper } from "src/components/ChoosePlan/components/wrappers/OneTokenWrapper";
import { SeveralTokenWrapper } from "src/components/ChoosePlan/components/wrappers/SeveralTokenWrapper";
import { TwoTokenWrapper } from "src/components/ChoosePlan/components/wrappers/TwoTokenWrapper";
import { PlanIds } from "src/constants/PlanIds";
import { ManyTokenIcon } from "src/icons/ManyTokensIcon";
import { OneTokenIcon } from "src/icons/OneTokenIcon";
import { SeveralTokensIcon } from "src/icons/SeveralTokensIcon";
import { TwoTokensIcon } from "src/icons/TwoTokensIcon";

export const plansDetails: {
  [key: string]: {
    icon: FC<{ active?: boolean }>;
    bestOffer: boolean;
    defaultSelected: boolean;
    IconWrapper?: FC<PropsWithChildren>;
  };
} = {
  [PlanIds.One]: {
    icon: OneTokenIcon,
    IconWrapper: OneTokenWrapper,
    bestOffer: false,
    defaultSelected: false,
  },
  [PlanIds.Two]: {
    icon: TwoTokensIcon,
    IconWrapper: TwoTokenWrapper,
    bestOffer: false,
    defaultSelected: true,
  },
  [PlanIds.Several]: {
    icon: SeveralTokensIcon,
    IconWrapper: SeveralTokenWrapper,
    bestOffer: true,
    defaultSelected: false,
  },
  [PlanIds.Many]: {
    icon: ManyTokenIcon,
    IconWrapper: ManyTokenWrapper,
    bestOffer: false,
    defaultSelected: false,
  },
};
