import { useAnalytics } from "@flowriver/analytics";
import {
  Button,
  Grid,
  Link,
  Skeleton,
  useMediaQuery,
  useTheme,
} from "@flowriver/ui-kit";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlanItem } from "src/components/ChoosePlan/components/PlanItem";
import { plansDetails } from "src/components/ChoosePlan/constants/plansDetails";
import { AmplitudeEventName } from "src/constants/analytics";
import { formatPrice } from "src/helpers/formatPrice";
import { useGetProducts } from "src/hooks/useGetProducts";

type Props = {
  isLoading?: boolean;
  onBuyClick: (id: number) => void;
  onContactClick: () => void;
};

const defaultSelectedPlanId = Object.entries(plansDetails).find(
  ([, plan]) => plan.defaultSelected,
)?.[0];

export const ChoosePlan: FC<Props> = ({
  isLoading,
  onBuyClick,
  onContactClick,
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { amplitude } = useAnalytics();

  const [activePlan, setActivePlan] = useState<number | undefined>(
    defaultSelectedPlanId ? Number(defaultSelectedPlanId) : undefined,
  );

  const { data: plans, isLoading: productsLoading } = useGetProducts();

  const selectedPlan = plans?.items?.find(({ id }) => id === activePlan);
  const priceAmount = selectedPlan?.price;
  const tokens = selectedPlan?.credits;
  const currency = selectedPlan?.currency;

  const price =
    priceAmount && currency
      ? formatPrice({ price: parseFloat(priceAmount), currency })
      : "";

  const handleClickPlan = (id?: number) => () => setActivePlan(id);

  const handleBuyClick = () => {
    if (activePlan) {
      amplitude.logEvent(AmplitudeEventName.PaywallBuyTapped, {
        credits: tokens,
        price: priceAmount,
      });

      onBuyClick(activePlan);
    }
  };

  const disabled = productsLoading || isLoading;

  return (
    <Grid container flexDirection="column">
      <Grid container justifyContent="center">
        <Grid
          container
          gap={4}
          mb={7}
          alignItems="flex-end"
          flexDirection={isMobile ? "column" : "row"}
        >
          {plans ? (
            plans.items?.map((plan) => (
              <PlanItem
                key={`select-plan-${plan.id}`}
                plan={plan}
                active={plan.id === activePlan}
                onSelect={handleClickPlan(plan.id)}
              />
            ))
          ) : (
            <>
              <Skeleton variant="rounded" width={156} height={156} />
              <Skeleton variant="rounded" width={156} height={156} />
              <Skeleton variant="rounded" width={156} height={156} />
              <Skeleton variant="rounded" width={156} height={156} />
            </>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="center" mb={6}>
        <Grid maxWidth={300} width="100%">
          {plans ? (
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disabled={disabled}
              sx={{ width: "100%" }}
              onClick={handleBuyClick}
            >
              {t("choose_plan.button_text", { count: tokens, price })}
            </Button>
          ) : (
            <Skeleton variant="rounded" width={156} height={32} />
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Link onClick={onContactClick}>{t("choose_plan.contact_us")}</Link>
      </Grid>
    </Grid>
  );
};
