import * as amplitude from "@amplitude/analytics-browser";
export class Amplitude {
    constructor({ apiKey, isProduction }) {
        this.apiKey = apiKey;
        this.isProduction = isProduction;
    }
    static getInstance(options) {
        if (!Amplitude.instance) {
            Amplitude.instance = new Amplitude(options);
            if (options.apiKey && options.isProduction) {
                amplitude.init(options.apiKey, {
                    defaultTracking: {
                        attribution: true,
                        formInteractions: true,
                        pageViews: true,
                        sessions: true,
                        fileDownloads: false,
                    },
                });
            }
        }
        return Amplitude.instance;
    }
    logEvent(eventName, eventProperties) {
        if (!this.apiKey || !this.isProduction) {
            console.log(`Dev mode: Sending event "${eventName}" to amplitude with properties ${JSON.stringify(eventProperties)}`);
            return;
        }
        amplitude.logEvent(eventName, eventProperties);
    }
}
Amplitude.instance = null;
