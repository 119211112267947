import { Box, Grid, ThemeMode, Typography } from "@flowriver/ui-kit";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import logoDark from "src/assets/logo.svg";
import logoLight from "src/assets/logo-large.svg";
import { PUBLIC_URL } from "src/constants/environments";
import { useSaveAppUrl } from "src/hooks/useSaveAppUrl";
import { useSettingsStore } from "src/hooks/useSettingsStore";
import bgBottom from "src/layouts/AuthLayout/assets/background-bottom.svg";
import bgTop from "src/layouts/AuthLayout/assets/background-top.svg";
import checkIcon from "src/layouts/AuthLayout/assets/check-icon.svg";

export const AuthLayout: FC = () => {
  const { t } = useTranslation();
  const { theme } = useSettingsStore();

  useSaveAppUrl();

  const advantages = [
    t("auth_layout.advantages.smart_web_apps"),
    t("auth_layout.advantages.live_competitor"),
    t("auth_layout.advantages.alternative_flow"),
    t("auth_layout.advantages.analysis"),
  ];

  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{ backgroundColor: "background.paper", minHeight: "100vh" }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        sx={{ minHeight: "100%" }}
      >
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            left: "32px",
            top: "32px",
            cursor: "pointer",
            borderRadius: 2,
            ["&:hover"]: {
              backgroundColor: "background.default",
            },
          }}
          p={2}
          component="a"
          href={PUBLIC_URL}
        >
          <img
            src={theme === ThemeMode.light ? logoLight : logoDark}
            width={136}
            height={24}
            alt="logo"
          />
        </Box>
        <Outlet />
      </Grid>
      <Grid
        container
        sx={{
          width: "536px",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "common.blue",
          paddingTop: "250px",
          position: "relative",
          [":before"]: {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "257px",
            backgroundImage: `url(${bgTop})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          },
          backgroundImage: `url(${bgBottom})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Grid container flexDirection="column" width={370}>
          <Grid mb={5}>
            <Typography
              fontWeight="bold"
              sx={{
                fontSize: "20px",
                lineHeight: "150%",
                color: "common.softGray",
                fontFamily: "Space Grotesk",
              }}
            >
              {t("auth_layout.advantages_description")}
            </Typography>
          </Grid>
          <Grid container flexDirection="column" gap={3}>
            {advantages.map((advantage, index) => (
              <Grid
                container
                key={`${advantage}-${index}`}
                alignItems="center"
                gap={2}
              >
                <img src={checkIcon} alt="check" width={26} height={26} />
                <Typography
                  variant="subtitle2"
                  fontFamily="Space Grotesk"
                  color="#AFD7FF"
                >
                  {advantage}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
