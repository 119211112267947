import { BaseButton, Grid, styled, Typography } from "@flowriver/ui-kit";
import { Trans } from "react-i18next";

const SupportButton = styled(BaseButton)(({ theme }) => ({
  textDecoration: "underline",
  padding: 0,
  border: 0,
  outline: 0,
  backgroundColor: "transparent",
  cursor: "pointer",
  color: theme.palette.common.darkGray,
  fontSize: theme.typography.body1.fontSize,
  lineHeight: theme.typography.body1.lineHeight,
  fontWeight: theme.typography.body1.fontWeight,
  fontFamily: theme.typography.body1.fontFamily,
  transition: "color 0.3s ease",

  ":hover, :visited:hover": {
    color: theme.palette.common.black,
  },
  ":visited": {
    color: theme.palette.common.darkGray,
  },
}));

export const SupportBlock = () => {
  return (
    <Grid container>
      <Typography variant="body1" color="common.darkGray">
        <Trans
          i18nKey="support_block.description"
          components={[<SupportButton />]}
        />
      </Typography>
    </Grid>
  );
};
