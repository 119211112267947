import { jsx as _jsx } from "react/jsx-runtime";
import { AnalyticsContext } from "./AnalyticsContext";
import { Amplitude } from "./services/Amplitude";
export const AnalyticsProvider = ({ amplitudeApiKey, isProduction, children, }) => {
    const amplitude = Amplitude.getInstance({
        isProduction,
        apiKey: amplitudeApiKey,
    });
    return (_jsx(AnalyticsContext.Provider, { value: { amplitude }, children: children }));
};
