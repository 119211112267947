import { ThemeMode } from "@flowriver/ui-kit";
import { ONE_MINUTE_IN_SECONDS } from "src/constants/constants";

export enum DeskType {
  Web = "web",
  Mobile = "mobile",
  Dashboard = "dashboard",
}

export const DESK_PALETTE: Record<ThemeMode, Record<string, string>> = {
  [ThemeMode.light]: {
    workingZone: "#EDF0F5",
    section: "#F2F6FC",
    netSmall: "#E0E7EE",
    netLarge: "#C3D1DE",
    edgeFocus: "#000000",
  },
  [ThemeMode.dark]: {
    workingZone: "#19191A",
    section: "#23282C",
    netSmall: "#1F2022",
    netLarge: "#23282C",
    edgeFocus: "#ffffff",
  },
};

export enum ViewMode {
  Desk = "desk",
  Preview = "preview",
  Progress = "progress",
  Remap = "remap",
  ServiceOverloaded = "service_overloaded",
  Loading = "loading",
  Error = "error",
}

export enum SectionName {
  Onboarding = "onboarding",
  Product = "product",
  Paywall = "paywall",
  Other = "other",
  Notification = "notification mapping",
}

export enum NodeType {
  Screen = "screen",
  Group = "group",
  Section = "section",
  Cluster = "cluster",
}

export enum VersionFilters {
  All = "all",
  Last = "last",
  Three = "three",
}

export const FiltersCount = {
  [VersionFilters.All]: undefined,
  [VersionFilters.Last]: 1,
  [VersionFilters.Three]: 3,
};

export enum EdgeType {
  Smoothstep = "smoothstep",
  Smart = "smart",
}

export const FLOW_Z_INDEX = {
  controls: 5,
  edge: 1,
};

export const NODE_SPACE_X = 350;
export const NODE_SPACE_Y = 800;
export const NODE_SPACE_CLUSTER_Y = 125;
export const MAX_NODES_IN_ROW = 300;
export const NODE_START_X = 300;
export const NODE_START_Y = 300;

export const SECTION_SPACE_X = 2000;
export const SECTION_SPACE_Y = 2000;
export const MAX_SECTION_IN_ROW = 3;
export const SECTION_START_X = 0;
export const SECTION_START_Y = 0;
export const LOADING_SECTION_WIDTH = 8000;
export const LOADING_SECTION_HEIGHT = 4500;

export const CLUSTER_HEIGHT = 200;
export const CLUSTER_SPACE_Y = 200;

export const NET_SMALL_GAP = 200;
export const NET_LARGE_GAP = 800;

export const REFETCH_DELAY = 10000;
export const OVERLOAD_DURATION = 5 * ONE_MINUTE_IN_SECONDS;
export const DESK_CONTAINER_ID = "desk-portal";
