import { useModal } from "mui-modal-provider";
import { useEffect } from "react";
import { BookCallModal } from "src/components/BookCallModal/BookCallModal";
import { LocalStorageKeys } from "src/constants/localStorageKeys";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { useSessionStorage } from "src/hooks/useSessionStorage";

const SHOWN_LIMIT = 3;

export const useBookCallModal = () => {
  const { showModal, state } = useModal();
  const localStorage = useLocalStorage();
  const sessionStorage = useSessionStorage();

  const onBook = () =>
    localStorage.setItem(LocalStorageKeys.BookCallShownCount, `${SHOWN_LIMIT}`);

  const showBookCallModal = () =>
    showModal(BookCallModal, { open: true, onBook });

  useEffect(() => {
    const modalShownCount = localStorage.getItem(
      LocalStorageKeys.BookCallShownCount,
    );
    const wasShown = sessionStorage.getItem(LocalStorageKeys.BookCallShown);

    const hasOpenedModal = Object.values(state)?.find(
      (modal) => modal?.props?.open === true,
    );

    const shownCount = modalShownCount ? parseInt(modalShownCount) : 0;

    const shouldShowModal =
      !wasShown && shownCount < SHOWN_LIMIT && !hasOpenedModal;

    if (!shouldShowModal) {
      return;
    }

    sessionStorage.setItem(LocalStorageKeys.BookCallShown, "true");
    localStorage.setItem(
      LocalStorageKeys.BookCallShownCount,
      (shownCount + 1).toString(),
    );
    showBookCallModal();
  }, []);
};
