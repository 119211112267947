import { useAnalytics } from "@flowriver/analytics";
import { Grid } from "@flowriver/ui-kit";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { FC, useEffect } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { paymentOrderComplete } from "src/api/paymentOrderComplete";
import { usePaymentStore } from "src/components/PaymentModal/store/usePaymentStore";
import { stripePromise } from "src/configs/stripe";
import { AmplitudeEventName } from "src/constants/analytics";
import { PaymentStatuses } from "src/constants/PaymentStatuses";
import { QueryKeys } from "src/constants/queryKeys";
import { useConnectMobileApp } from "src/hooks/useConnectMobileApp";
import { useConnectMobileAppByUrl } from "src/hooks/useConnectMobileAppByUrl";

export const Content: FC = () => {
  const { t } = useTranslation();

  const { amplitude } = useAnalytics();

  useEffect(() => {
    amplitude.logEvent(AmplitudeEventName.CheckoutViewed);
  }, []);

  const {
    clientSecret,
    orderId,
    appId,
    appUrl,
    handleSuccessPayment,
    onPurchase,
  } = usePaymentStore();

  const queryClient = useQueryClient();
  const { mutateAsync: addAppById } = useConnectMobileApp();
  const { mutateAsync: addAppByLink } = useConnectMobileAppByUrl();

  const { mutate } = useMutation({
    mutationFn: (id: number) => paymentOrderComplete({ id }),
    mutationKey: [QueryKeys.PaymentOrderComplete],
    onSuccess: async ({ data }) => {
      if (data.status === PaymentStatuses.Succeeded) {
        try {
          if (appId) {
            await addAppById(appId);
          }
          if (appUrl) {
            await addAppByLink(appUrl);
          }
          if (onPurchase) {
            onPurchase();
          }
        } catch {
          // sad but continue
          console.error(
            `Failed to add appId - "${appId}" by link - "${appUrl}"`,
          );
        }

        amplitude.logEvent(AmplitudeEventName.PurchaseComplited);

        queryClient.invalidateQueries({
          queryKey: [QueryKeys.CurrentUser],
          type: "all",
        });

        handleSuccessPayment();

        return;
      }
      if (data.status === PaymentStatuses.Created) {
        enqueueSnackbar(
          t("payment_modal.payment.payment_created_notification"),
          { variant: "info" },
        );
        return;
      }

      enqueueSnackbar(t("common.unknown"), { variant: "error" });
      console.error("Payment failed");
    },
  });

  const handleCompletePayment = useCallback(() => {
    if (orderId) {
      mutate(orderId);
    }
  }, []);

  return (
    <Grid
      container
      width="100vw"
      maxWidth={1000}
      height="90vh"
      py={1}
      // provider can't have night theme, so we need to set one color background
      sx={{ backgroundColor: "common.white" }}
    >
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{
          clientSecret,
          onComplete: handleCompletePayment,
        }}
      >
        <Grid container flexDirection="column" width="100%">
          <EmbeddedCheckout />
        </Grid>
      </EmbeddedCheckoutProvider>
    </Grid>
  );
};
