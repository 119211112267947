import { MenuItem } from "@flowriver/ui-kit";
import { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BetaBadge } from "src/components/BetaBadge/BetaBadge";
import { NewBadge } from "src/components/NewBadge/NewBadge";
import { Routes } from "src/constants/routes";

type Props = {
  label: string;
  link: Routes;
  icon: JSX.Element;
  isBeta: boolean;
  isNew: boolean;
  onClick: () => void;
};

const getIsActive = (link: Routes, pathname: string) => {
  switch (link) {
    case Routes.Web2App: {
      return pathname === Routes.Web2App || pathname.startsWith("/web2app/");
    }
    default: {
      return pathname.startsWith(link);
    }
  }
};

export const AppMenuItem: FC<Props> = ({
  label,
  link,
  icon,
  isBeta,
  isNew,
  onClick,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);
  const isActive = getIsActive(link, pathname);
  const isBadgeActive = isActive || isHovered;

  const handleTabClick = (link: string) => () => {
    navigate(link);
    onClick?.();
  };
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <MenuItem
      onClick={handleTabClick(link)}
      active={isActive}
      icon={icon}
      color="dark"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {label}
      {isBeta ? <BetaBadge active={isBadgeActive} /> : null}
      {isNew ? <NewBadge active={isBadgeActive} /> : null}
    </MenuItem>
  );
};
