import { useModal } from "mui-modal-provider";
import { useCreatePaymentOrder } from "src/components/PaymentModal/hooks/useCreatePaymentOrder";
import { PaymentModal } from "src/components/PaymentModal/PaymentModal";
import { usePaymentStore } from "src/components/PaymentModal/store/usePaymentStore";

export const usePayment = () => {
  const { showModal } = useModal();

  const { startPayment, choseTariff } = usePaymentStore();

  const { mutate } = useCreatePaymentOrder({
    onSuccess: ({ clientSecret, orderId, productId }) => {
      startPayment({ clientSecret, orderId, productId });
    },
  });

  const startPaymentProcess = (id: number) => {
    startPayment({});
    showModal(PaymentModal);
    mutate(id);
  };

  const chooseProductProcess = ({
    url,
    appId,
    onPurchase,
  }: {
    url?: string;
    appId?: string;
    onPurchase?: () => void;
  }) => {
    choseTariff({ url, appId, onPurchase });
    showModal(PaymentModal);
  };

  return {
    startPayment: startPaymentProcess,
    chooseProduct: chooseProductProcess,
  };
};
