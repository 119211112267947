import { default as instance } from "axios";
import qs from "qs";
import { queryClient } from "src/configs/queryClient";
import { API_URL } from "src/constants/environments";
import { QueryKeys } from "src/constants/queryKeys";
import { StatusCodes } from "src/constants/statuses";

export const api = instance.create({
  baseURL: API_URL,
  headers: { "Content-Type": "application/json" },
  paramsSerializer: (params) =>
    qs.stringify(params, { arrayFormat: "brackets" }),
});

api.interceptors.response.use(null, (error) => {
  if (error?.response?.status === StatusCodes.UNAUTHORIZED) {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.WhoAmI],
      type: "all",
    });
  }
  return Promise.reject(error);
});
